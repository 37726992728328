import { cleanDocumentString } from "../lib/validators"
import { CPF_LENGTH } from "../lib/masks"
import axios, { SUPPLIERS_URL } from "./axios"

export const getSuppliers = () => {
  return axios.get(SUPPLIERS_URL)
}

export const getSupplier = ({ queryKey }) => {
  const id = queryKey[1]
  if (id) return axios.get(`${SUPPLIERS_URL}/${id}`)
}

export const deleteSupplier = (supplierId) => {
  return axios.delete(`${SUPPLIERS_URL}/${supplierId}`)
}

export const createSupplier = ({ data }) => {
  let body = {
    supplier: {},
  }

  Object.entries(data).forEach((keyValuePair) => {
    let key = keyValuePair[0]
    let value = keyValuePair[1]
    if (key === "document") {
      value = cleanDocumentString(value)
      if (value.length <= CPF_LENGTH) {
        return (body["supplier"]["cpf"] = value)
      }
      return (body["supplier"]["cnpj"] = value)
    }
    if (key === "codigo_municipio" && value === "") {
      return
    }
    return (body["supplier"][key] = value)
  })

  return axios.post(SUPPLIERS_URL, body)
}

export const updateSupplier = ({ id, data }) => {
  let body = {
    supplier: {},
  }

  Object.entries(data).forEach((keyValuePair) => {
    let key = keyValuePair[0]
    let value = keyValuePair[1]
    if (key === "document") {
      value = cleanDocumentString(value)
      if (value.length <= CPF_LENGTH) {
        return (body["supplier"]["cpf"] = value)
      }
      return (body["supplier"]["cnpj"] = value)
    }
    if (key === "codigo_municipio" && value === "") {
      return
    }
    if (key === "uf" && value === "") {
      return
    }
    if (key === "municipio" && value === "") {
      return
    }
    return (body["supplier"][key] = value)
  })

  return axios.patch(`${SUPPLIERS_URL}/${id}`, body)
}
