/* eslint-disable */
import PropTypes from "prop-types"

export default function ToogleFormField({
  field,
  form,
  label,
  labelClasses,
  textClasses,
  ...props
}) {
  return (
    <div className="flex">
      <input
        size={8}
        className="h-0 w-0 invisible peer"
        type="checkbox"
        checked={field.value}
        id={field.name}
        aria-label={field.name}
        {...field}
        {...props}
      />
      <label
        htmlFor={field.name}
        className={`cursor-pointer peer-disabled:opacity-50 peer-disabled:cursor-default -indent-[9999px] w-8 h-4 bg-zinc-400 block rounded-full relative after:content-[""] after:absolute after:top-[1px] after:left-[1px] after:w-[14px] after:h-[14px] after:bg-white after:rounded-full after:transition-all peer-checked:bg-primary transition-all peer-checked:after:left-[calc(100%-1px)] peer-checked:after:translate-x-[-100%] ${
          labelClasses ? labelClasses : ""
        }`}
      />
      <label
        htmlFor={field.name}
        className={`pl-1 text-xs whitespace-nowrap peer-disabled:opacity-50 cursor-pointer peer-disabled:cursor-default ${
          textClasses ? textClasses : ""
        }`}
      >
        {label}
      </label>
    </div>
  )
}

ToogleFormField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  labelClasses: PropTypes.string,
  textClasses: PropTypes.string,
  label: PropTypes.string,
}
