import SkeletonElement from "../../components/skeletons/SkeletonElement"

export default function NewUserFormLoading() {
  return (
    <>
      <SkeletonElement type="field" />
      <div data-testid="form-loading" className="flex">
        <SkeletonElement type="field" />
        <SkeletonElement type="field" />
      </div>
      <div className="grid sm:grid-cols-2 items-center">
        <div className="flex items-center">
          <SkeletonElement type="field" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 hover:scale-125 text-black transition-all"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
            />
          </svg>
        </div>
        <div className="flex items-center justify-self-end mx-3">
          <button type="button" className="button cursor-pointer m-0">
            Gerar nova senha forte
          </button>
        </div>
      </div>
      <button type="button" className="button mt-1 mx-3">
        Salvar
      </button>
    </>
  )
}
