import * as Yup from "yup"

export const validationSchema = Yup.object().shape(
  {
    supplier_id: Yup.string()
      .required("Por favor, informe o fornecedor.")
      .default(""),
    name: Yup.string().required("Por favor, informe um nome.").default(""),
    email: Yup.string()
      .required("Por favor, informe um email.")
      .default("")
      .email("Por favor, informe um email válido!"),
    password: Yup.string()
      .default("")
      .when("editing_mode", {
        is: (value) => value === false,
        then: Yup.string().default("").required("Por favor, gere uma senha."),
      }),
    editing_mode: Yup.boolean().default(false),
  },
  [["editing_mode"]]
)

export const initialValues = Object.entries(validationSchema.fields).reduce(
  (total, currentValue) => {
    const name = currentValue[0]
    const defaultValue = currentValue[1].spec.default
    total[name] = defaultValue
    return total
  },
  {}
)
