import { useState, useMemo, useCallback } from "react"
import { initialValues, validationSchema } from "./newSupplierSchema"
import { useParams, useNavigate } from "react-router-dom"
import { cepMask, documentMask, telephoneMask } from "../../lib/masks"
import { Formik, Form, Field } from "formik"
import TextFormField from "../../components/forms/TextFormField"
import SelectFormField from "../../components/forms/SelectFormField"
import { useQuery } from "react-query"
import {
  createSupplier,
  getSupplier,
  updateSupplier,
} from "../../api/suppliersServices"
import { getCep } from "../../api/cepServices"
import NewSupplierFormLoading from "./NewSupplierFormLoading"
import { toast } from "react-toastify"

const toastConfig = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
}

export default function NewSupplierForm() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [cepValue, setCepValue] = useState(null)
  const { data: cep, isLoading: isCepLoading } = useQuery(
    ["cep", cepValue],
    getCep
  )

  const municipioData = useMemo(() => {
    if (!cep || cep?.data?.codigo === "nao_encontrado") return null
    const municipioCode = cep?.data?.["codigo_ibge"]
    const municipioName = cep?.data?.["nome_localidade"]
    return [{ value: municipioCode, label: municipioName }]
  }, [cep])

  const { data: supplier, isLoading: supplierLoading } = useQuery(
    ["supplier", id],
    getSupplier
  )
  const supplierId = supplier?.data?.data?.id

  const normalizeSuppliersAttributes = (supplierAttributes) =>
    Object.entries(supplierAttributes).reduce((total, keyValuePair) => {
      let key = keyValuePair[0]
      let value = keyValuePair[1]
      key = key.replaceAll("-", "_")

      if (!value) value = ""

      if (key === "cpf" || key === "cnpj") {
        if (value !== "") total["document"] = documentMask(value)
      } else if (key === "codigo_municipio") {
      } else {
        total[key] = value
      }

      return total
    }, {})

  const supplierData = useMemo(() => {
    const supplierAttributes = supplier?.data?.data?.attributes
    if (supplierAttributes)
      return normalizeSuppliersAttributes(supplierAttributes)
  }, [supplier])

  const serverErrorToMessage = (error) => {
    if (error?.["erros"]?.[0].includes("municipio ou sigla uf"))
      return "Nome do Município ou UF não encontrados, tente usar um CEP que funcione."
    if (error?.cpf) return "CPF já em uso por outro Fornecedor."
    if (error?.cnpj) return "CNPJ já em uso por outro Fornecedor."
    if (error?.email) return "Email já está em uso por outro Fornecedor."
    return "Um erro inesperado aconteceu, tente novamante!"
  }

  const handleSubmit = async ({ setSubmitting, values, resetForm }) => {
    const toastId = toast.loading("Validando o formulário...", toastConfig)
    setSubmitting(true)
    try {
      if (supplier) await updateSupplier({ data: values, id: supplierId })
      else {
        await createSupplier({ data: values })
        resetForm()
      }
      toast.update(toastId, {
        render: supplier
          ? "Mudanças salvas com sucesso!"
          : "Fornecedor criado com sucesso!",
        type: "success",
        isLoading: false,
        ...toastConfig,
      })
    } catch (e) {
      const error = e.response.data
      toast.update(toastId, {
        render: serverErrorToMessage(error),
        type: "error",
        isLoading: false,
        ...toastConfig,
      })
    } finally {
      setSubmitting(false)
    }
  }

  const bairroCallback = useCallback(() => {
    if (cep?.data?.codigo === "nao_encontrado")
      return { value: null, error: true }
    const bairro = cep?.data?.bairro
    return { value: bairro, error: null }
  }, [cep])

  const logradouroCallback = useCallback(() => {
    if (cep?.data?.codigo === "nao_encontrado")
      return { value: null, error: true }
    const logradouro = cep?.data?.nome_logradouro
    return { value: logradouro, error: null }
  }, [cep])

  const municipioCallback = useCallback(() => {
    if (municipioData) return { value: municipioData[0].value, error: null }
    return { value: null, error: true }
  }, [municipioData])

  const goToVisualizeUsers = () => {
    navigate(`/users?supplier_id=${id}`)
  }

  const goToCreateUser = () => {
    navigate(`/users/new?supplier_id=${id}`)
  }

  const goToSuppliersPage = () => {
    navigate(`/suppliers`)
  }

  if (supplierLoading) return <NewSupplierFormLoading />

  return (
    <Formik
      initialValues={supplierData || initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm, setSubmitting }) =>
        handleSubmit({ values, resetForm, setSubmitting })
      }
    >
      {({ isSubmitting }) => {
        const handleCepKeyUp = (e) => {
          const value = e.target.value
          if (value?.length === 9 && value !== cepValue) {
            setCepValue(value)
          }
        }

        return (
          <Form>
            <p
              onClick={goToSuppliersPage}
              className="underline w-fit px-4 mb-2 text-xs text-primary hover:text-blue-400 cursor-pointer"
            >
              Voltar para a página de fornecedores
            </p>
            <div className="grid md:grid-cols-3">
              <Field
                label="Razão Social*"
                name="razao_social"
                component={TextFormField}
              />
              <Field
                label="Nome fantasia*"
                name="nome_fantasia"
                component={TextFormField}
              />
              <Field
                maxLength="18"
                mask={documentMask}
                label="CNPJ/CPF*"
                name="document"
                component={TextFormField}
              />
              <Field
                label="Inscrição Municipal*"
                name="inscricao_municipal"
                component={TextFormField}
              />
              <Field
                maxLength="15"
                mask={telephoneMask}
                label="Telefone"
                name="telefone"
                component={TextFormField}
              />
              <Field label="Email" name="email" component={TextFormField} />
            </div>
            <div className="grid md:grid-cols-4">
              <Field
                disabled={isCepLoading}
                onKeyUp={handleCepKeyUp}
                maxLength="9"
                mask={cepMask}
                label="CEP*"
                name="cep"
                component={TextFormField}
              />

              <Field label="Nº *" name="numero" component={TextFormField} />
              <Field
                label="Complemento"
                name="complemento"
                component={TextFormField}
              />
              <Field
                callback={[bairroCallback, [cep]]}
                label="Bairro*"
                name="bairro"
                component={TextFormField}
              />
              <Field
                callback={[logradouroCallback, [cep]]}
                label="Logradouro*"
                name="logradouro"
                component={TextFormField}
              />
              {municipioData ? (
                <>
                  <Field
                    options={municipioData}
                    label="Município*"
                    name="codigo_municipio"
                    component={SelectFormField}
                    callback={[municipioCallback, [municipioData]]}
                  />
                </>
              ) : (
                <>
                  <Field
                    label="Município*"
                    name="municipio"
                    component={TextFormField}
                  />
                  <Field label="UF*" name="uf" component={TextFormField} />
                </>
              )}
            </div>
            <div className="flex flex-col">
              <div className="flex flex-row flex-wrap py-3">
                <button
                  disabled={isSubmitting}
                  className="button mx-2"
                  type="submit"
                >
                  Salvar
                </button>
                {id && (
                  <>
                    <button
                      type="button"
                      onClick={goToVisualizeUsers}
                      className="button mx-2"
                    >
                      Visualizar Usuários
                    </button>
                    <button
                      type="button"
                      onClick={goToCreateUser}
                      className="button mx-2"
                    >
                      Novo Usuário
                    </button>
                  </>
                )}
              </div>
            </div>
          </Form>
        )
      }}
    </Formik>
  )
}
