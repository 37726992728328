import { BrowserRouter as Router } from "react-router-dom"
import { AuthProvider } from "./contexts/authContext"
import { QueryClient, QueryClientProvider } from "react-query"
import Routes from "./Routes"
import "react-toastify/dist/ReactToastify.css"

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router>
          <Routes />
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  )
}
