import SkeletonElement from "./SkeletonElement"
import PropTypes from "prop-types"

export default function TableSkeleton({
  numberOfRows,
  numberOfHeads,
  numberOfDataTables,
}) {
  return (
    <table data-testid="loading-table" className="w-full text-left">
      <thead className="border-b-2 border-black">
        <tr>
          {[...Array(numberOfHeads)].map((_, index) => (
            <th key={index}>
              <SkeletonElement type="title" />
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-zinc-300 text-zinc-700">
        {[...Array(numberOfRows)].map((_, index) => (
          <tr className="even:bg-gray-100" key={index}>
            {[...Array(numberOfDataTables)].map((_, index) => (
              <td key={index} className="px-2 py-3">
                <SkeletonElement type="text" />
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  )
}

TableSkeleton.propTypes = {
  numberOfRows: PropTypes.number.isRequired,
  numberOfHeads: PropTypes.number.isRequired,
  numberOfDataTables: PropTypes.number.isRequired,
}
