import axios from "axios"

let baseURL

if (process.env.NODE_ENV === "test") {
  if (process.env.REACT_APP_USE_REAL_BACKEND === "enabled")
    baseURL = process.env.REACT_APP_REAL_BACKEND_URL
  else baseURL = process.env.REACT_APP_BACKEND_URL
} else baseURL = process.env.REACT_APP_BACKEND_URL

export const LOGIN_URL = "/ui/authentication"
export const USERS_URL = "/ui/users"
export const ORDERS_URL = "/ui/orders"
export const SUPPLIERS_URL = "/ui/suppliers"
export const NOTAS_NFSE_URL = "/ui/notas_fiscais_servico?limit=20"
export const COMPANIES_URL = "/ui/companies"
export const CNAECODES_URL = "/ui/codigos_cnae"
export const COUNTIES_URL = "/ui/municipios"
export const NOTA_NFSE_URL = "/ui/notas_fiscais_servico"
export const CEP_URL = "/ui/ceps"

export default axios.create({
  baseURL,
  headers: {
    "content-type": "application/json",
  },
})
