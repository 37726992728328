import { useNavigate } from "react-router-dom"

export default function NfseTableRow({ nota, relations }) {
  const navigate = useNavigate()

  const findSupplierNameWithId = (id) => {
    const supplier = relations?.find(
      (related) => related.id === String(id) && related.type === "suppliers"
    )
    const supplierName = supplier?.attributes["razao-social"]
    return supplierName
  }

  const prettifyDate = (isoDate) => {
    const date = new Date(isoDate)
    return date.toLocaleDateString("en-GB") // dd/mm/yyyy
  }

  const prettifyValue = (value) => {
    return parseFloat(value).toFixed(2) // 102 => 102.00
  }

  const handleButtonClick = () => {
    navigate(`/nfse/${nota.id}`)
  }

  return (
    <tr className="even:bg-gray-100 hover:bg-gray-200 transition-all">
      <td className="px-2 py-3 text-sm w-24">
        {prettifyDate(nota.attributes["data-emissao"])}
      </td>
      <td className="px-2 py-3 text-sm max-w-[188px] ellipsis">
        {findSupplierNameWithId(nota.attributes["supplier-id"])}
      </td>
      <td className="px-2 py-3 text-sm w-24">{nota.attributes["numero"]}</td>
      <td className="px-2 py-3 text-sm w-24">
        R$ {prettifyValue(nota.attributes["valor-servicos"])}
      </td>
      <td className="text-right w-32">
        <button
          onClick={handleButtonClick}
          className="inline-block px-2 mr-2 py-1 w-fit self-end text-sm rounded-md bg-primary hover:bg-blue-700 transition-all hover:shadow-md text-[#fff]"
        >
          Visualizar
        </button>
      </td>
    </tr>
  )
}
