import SkeletonElement from "../../components/skeletons/SkeletonElement"

export default function NFSeLoading() {
    return (
        <div data-testid="form-loading" className="w-full mb-24">
            <div className="w-full rounded-md md:p-4 mt-12 border-2">
                <div className="grid grid-cols-1">
                    <SkeletonElement type="field" />
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2">
                    <SkeletonElement type="field" />
                    <SkeletonElement type="field" />
                </div>
            </div>
            <div className="w-full rounded-md md:p-4 mt-12 border-2">
                <div className="grid grid-cols-1 md:grid-cols-3">
                    <SkeletonElement type="field" />
                    <SkeletonElement type="field" />                    
                    <SkeletonElement type="field" />
                    <SkeletonElement type="field" />
                    <SkeletonElement type="field" />
                    <SkeletonElement type="field" />
                </div>
                <div className="grid grid-cols-1">
                    <div className="flex">
                        <SkeletonElement type="field" />
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mt-5 mr-4 cursor-pointer hover:scale-125 text-black transition-all" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                        </svg>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3">
                    <SkeletonElement type="field" />
                    <SkeletonElement type="field" />                    
                    <SkeletonElement type="field" />
                    <SkeletonElement type="field" />
                    <SkeletonElement type="field" />
                    <SkeletonElement type="field" />
                </div>
            </div>
        </div>
    )
}
