import { useEffect, useMemo, useState } from "react"
import { useQuery } from "react-query"
import TableSkeleton from "../../components/skeletons/TableSkeleton"
import { getSuppliers } from "../../api/suppliersServices"
import UsersSearch from "./UsersSearch"
import UsersErrorTable from "./UsersErrorTable"
import UsersTable from "./UsersTable"
import { getUsers } from "../../api/usersServices"
import { useLocation } from "react-router-dom"

export default function Users() {
  const { search: queryString } = useLocation()
  const params = new URLSearchParams(queryString)
  const supplierId = params.get("supplier_id")
  const { data: suppliers } = useQuery(["suppliers"], getSuppliers)
  const { data: users, isLoading, isError } = useQuery(["users"], getUsers)
  const [filter, setFilter] = useState("")

  const dataToPopulateTable = useMemo(() => {
    const data = users?.data?.data
    if ((filter === "" && !supplierId) || !data || data.length === 0)
      return data

    if (supplierId)
      return data
        .filter((data) => {
          return data.attributes["supplier-id"] === Number(supplierId)
        })
        .filter((data) =>
          Object.values(data.attributes)
            .join(" ")
            .toLocaleLowerCase()
            .includes(filter.toLocaleLowerCase())
        )
    else
      return data.filter((data) =>
        Object.values(data.attributes)
          .join(" ")
          .toLocaleLowerCase()
          .includes(filter.toLocaleLowerCase())
      )
  }, [filter, users, supplierId])

  const handleFilterChange = (valueSearchInput) => {
    setFilter(valueSearchInput.trim())
  }

  const getSupplierWithId = (id) => {
    return suppliers?.data?.data?.find(
      (supplier) => String(supplier.id) === String(id)
    )
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  return (
    <main className="w-full flex flex-col justify-center items-center">
      <div className="max-w-6xl w-full flex flex-col px-4 pt-16">
        <UsersSearch handleFilterChange={handleFilterChange} />
        <div className="border-2 p-4 mt-16 text-black rounded-md shadow overflow-auto mb-8">
          <h1 className="text-3xl px-2 mb-2 font-semibold text-primary whitespace-nowrap">
            Usuários
          </h1>
          {isLoading ? (
            <TableSkeleton
              numberOfRows={10}
              numberOfHeads={3}
              numberOfDataTables={6}
            />
          ) : dataToPopulateTable?.length === 0 || isError ? (
            <UsersErrorTable />
          ) : (
            <UsersTable
              getSupplierWithId={getSupplierWithId}
              data={dataToPopulateTable}
            />
          )}
        </div>
      </div>
    </main>
  )
}
