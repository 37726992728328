import SkeletonElement from "../../components/skeletons/SkeletonElement"

export default function NewSupplierFormLoading() {
  return (
    <>
      <div data-testid="form-loading" className="grid md:grid-cols-3">
        <SkeletonElement type="field" />
        <SkeletonElement type="field" />
        <SkeletonElement type="field" />
        <SkeletonElement type="field" />
        <SkeletonElement type="field" />
        <SkeletonElement type="field" />
      </div>
      <div className="grid md:grid-cols-4">
        <SkeletonElement type="field" />
        <SkeletonElement type="field" />
        <SkeletonElement type="field" />
        <SkeletonElement type="field" />
        <SkeletonElement type="field" />
        <SkeletonElement type="field" />
        <SkeletonElement type="field" />
      </div>
      <div className="flex flex-row flex-wrap py-3">
        <button className="button mx-2">Salvar</button>
        <button className="button mx-2">Visualizar Usuários</button>
        <button className="button mx-2">Novo Usuário</button>
      </div>
    </>
  )
}
