import { cleanDocumentString } from "./validators"

export const CPF_LENGTH = 11

export const cepMask = (cepStr) => {
  cepStr = cepStr.replace(/\D/g, "")
  cepStr = cepStr.replace(/^(\d{5})(\d)/, "$1-$2")
  return cepStr
}

export const documentMask = (document) => {
  document = cleanDocumentString(document)
  if (document.length <= CPF_LENGTH) {
    document = document.replace(/(\d{3})(\d)/, "$1.$2")
    document = document.replace(/(\d{3})(\d)/, "$1.$2")
    document = document.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    return document
  }
  document = document.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
    "$1.$2.$3/$4-$5"
  )
  return document
}

export const telephoneMask = (telephone) => {
  telephone = telephone.replace(/\D/g, "")
  telephone = telephone.replace(/^(\d{2})(\d)/g, "($1) $2")
  telephone = telephone.replace(/(\d)(\d{4})$/, "$1-$2")
  return telephone
}
