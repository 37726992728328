import { useState, useRef } from "react"
import { useQuery } from "react-query"
import { getOrders } from "../../api/NFSeServices"
import { getSuppliers } from "../../api/suppliersServices"

export default function NfseSearchFilters({
  setChosenOrderId,
  setChosenSupplierId,
  chosenSupplierId,
  clearFilters,
}) {
  const suppliersOption = useRef()
  const ordersOption = useRef()
  const [menuOpened, setMenuOpened] = useState(false)
  const { data: orders } = useQuery(["orders", chosenSupplierId], getOrders)
  const { data: suppliers } = useQuery(["suppliers"], getSuppliers)

  const handleSupplierChange = (e) => {
    setChosenSupplierId(e.target.value)
    setChosenOrderId("TODOS")
  }

  const handleClearFilters = () => {
    suppliersOption.current.value = "TODOS"
    ordersOption.current.value = "TODOS"
    clearFilters()
  }

  return (
    <>
      <div
        onClick={() => setMenuOpened((prevState) => !prevState)}
        className="pt-4 pb-1 flex w-fit items-center space-x-1 hover:scale-105 transition-all cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-primary"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
        </svg>
        <p data-testid="filters-button" className="text-black">
          Filtros de pesquisa
        </p>
      </div>
      <div
        className={`border-2 p-4 text-black rounded-md shadow transition-all overflow-hidden ${
          menuOpened ? "opacity-100" : "h-0 p-0 border-0 opacity-0"
        }`}
      >
        <div
          onClick={handleClearFilters}
          className="flex mb-2 w-fit items-center space-x-1 hover:scale-105 transition-all cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 text-primary"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            />
          </svg>
          <p className="text-black">Limpar filtros</p>
        </div>
        <div className="flex space-x-2 items-center my-2">
          <p className="font-semibold text-md sm:text-lg pr-1">Fornecedor:</p>
          <select
            ref={suppliersOption}
            onChange={handleSupplierChange}
            className="w-full uppercase cursor-pointer bg-blue-50 rounded-md px-2 py-1 focus:outline-none whitespace-nowrap overflow-ellipsis overflow-hidden"
          >
            <option>TODOS</option>
            {suppliers?.data?.data?.map((supplier) => (
              <option key={supplier.id} value={supplier.id}>
                {supplier.attributes["razao-social"]}
              </option>
            ))}
          </select>
        </div>
        <div className="flex space-x-2 items-center my-2 mb-0">
          <p className="font-semibold text-md sm:text-lg pr-1">Pedido:</p>
          <select
            ref={ordersOption}
            onChange={(e) => setChosenOrderId(e.target.value)}
            className="w-full uppercase cursor-pointer bg-blue-50 rounded-md px-2 py-1 focus:outline-none whitespace-nowrap overflow-ellipsis overflow-hidden"
          >
            <option>TODOS</option>
            {orders?.data?.data?.map((order) => (
              <option key={order.id} value={order.id}>
                {order.attributes?.description}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  )
}
