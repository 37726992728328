import * as Yup from "yup"
import { validateCNPJ, validateCPF } from "../../lib/validators"

export const validationSchema = Yup.object().shape(
  {
    razao_social: Yup.string()
      .required("Por favor, informe uma Razão social.")
      .default(""),
    nome_fantasia: Yup.string()
      .required("Por favor, informe um nome.")
      .default(""),
    document: Yup.string()
      .required("Por favor, informe um CNPJ ou CPF.")
      .default("")
      .test(
        "verify_document",
        "Por favor, insira um CNPJ ou CPF válido!",
        (value) => {
          const isValidCPF = validateCPF(value)
          if (isValidCPF) return true
          const isValidCNPJ = validateCNPJ(value)
          if (isValidCNPJ) return true
          return false
        }
      ),
    inscricao_municipal: Yup.string()
      .required("Por favor, informe uma Inscrição Municipal.")
      .default(""),
    telefone: Yup.string().default(""),
    email: Yup.string().default("").email("Por favor, insira um email válido!"),
    cep: Yup.string()
      .default("")
      .required("Por favor, informe um CEP.")
      .test("verify_cep", "Por favor, insira um CEP válido!", (value) => {
        value = value.replace(/[^\d]/g, "")
        return value.length === 8
      }),
    logradouro: Yup.string()
      .required("Por favor, informe um Logradouro.")
      .default(""),
    numero: Yup.string().required("Por favor, informe um Número.").default(""),
    complemento: Yup.string().default(""),
    bairro: Yup.string().required("Por favor, informe um Bairro.").default(""),
    codigo_municipio: Yup.string()
      .default("")
      .when("municipio", {
        is: (value) => value === "",
        then: Yup.string()
          .default("")
          .required("Por favor, informe um Município."),
      }),
    municipio: Yup.string()
      .default("")
      .when("codigo_municipio", {
        is: (value) => value === "",
        then: Yup.string()
          .default("")
          .required("Por favor, informe um Município."),
      }),
    uf: Yup.string()
      .default("")
      .when("codigo_municipio", {
        is: (value) => value === "",
        then: Yup.string()
          .default("")
          .required("Por favor, informe uma UF (Exemplo: PR)."),
      }),
  },
  [["municipio", "codigo_municipio"]]
)

export const initialValues = Object.entries(validationSchema.fields).reduce(
  (total, currentValue) => {
    const name = currentValue[0]
    const defaultValue = currentValue[1].spec.default
    total[name] = defaultValue
    return total
  },
  {}
)
