import NewSupplierForm from "./NewSupplierForm"
import { useEffect } from "react"
import { ToastContainer } from "react-toastify"

export default function NewSupplier() {
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  return (
    <main className="w-full flex flex-col justify-center items-center">
      <div className="max-w-7xl w-full flex flex-col px-4 pt-8">
        <div className="p-4 shadow-md text-center w-full border-2 rounded-md">
          <h1 className="text-4xl text-primary font-medium">
            Cadastro de Fornecedor
          </h1>
        </div>
        <div className="justify-center items-center w-full border-2 rounded-md p-4 my-8">
          <NewSupplierForm />
          <ToastContainer />
        </div>
      </div>
    </main>
  )
}
