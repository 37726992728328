import NfseTableRow from "./NfseTableRow"

export default function NfseTable({ notas_nfse }) {
  return (
    <table data-testid="nfse-actual-table" className="w-full text-left">
      <thead className="border-b-2 border-black whitespace-nowrap">
        <tr>
          <th className="p-2 text-sm font-semibold tracking-wide">
            Data de Emissão
          </th>
          <th className="p-2 text-sm font-semibold tracking-wide">
            Fornecedor
          </th>
          <th className="p-2 text-sm font-semibold tracking-wide">Número</th>
          <th className="p-2 text-sm font-semibold tracking-wide">Valor</th>
        </tr>
      </thead>
      <tbody className="divide-y divide-zinc-300 text-zinc-700 whitespace-nowrap">
        {notas_nfse?.data?.map((nota) => (
          <NfseTableRow
            relations={notas_nfse.included}
            key={nota.id}
            nota={nota}
          />
        ))}
      </tbody>
    </table>
  )
}
