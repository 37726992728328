import { useState } from "react"
import { useNavigate } from "react-router-dom"

export default function UsersSearch({ handleFilterChange }) {
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState("")

  const goToNewUsersPage = () => {
    navigate("/users/new")
  }

  const applyFilters = (e) => {
    e.preventDefault()
    handleFilterChange(searchValue)
  }

  const handleSeachValueChange = (e) => {
    setSearchValue(e.target.value)
    if (e.target.value === "") handleFilterChange("")
  }

  return (
    <div className="flex flex-col sm:flex-row w-full">
      <form
        onSubmit={applyFilters}
        className="flex rounded-full shadow border-2 p-4 w-full"
      >
        <input
          value={searchValue}
          onChange={handleSeachValueChange}
          type="text"
          className="focus:outline-none w-full p-2 rounded-md rounded-br-none rounded-tr-none bg-blue-50 text-black"
          placeholder="Pesquisar Usuário"
        />
        <button
          type="submit"
          onClick={applyFilters}
          className="bg-primary p-2 text-white rounded-md rounded-tl-none rounded-bl-none font-medium hover:bg-blue-700 transition-all"
        >
          Pesquisar
        </button>
      </form>
      <button
        onClick={goToNewUsersPage}
        name="newSupplier"
        className="flex items-center rounded-full shadow border-2 p-4 ml-0 mt-4 sm:ml-6 sm:mt-0 self-start sm:self-auto hover:scale-105 transition-all cursor-pointer whitespace-nowrap text-lg text-black font-medium"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 bg-primary rounded-full mr-2 text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          />
        </svg>
        Novo Usuário
      </button>
    </div>
  )
}
