/* eslint-disable */
import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { getIn } from "formik"

export default function SelectFormField({
  field,
  form,
  options,
  callback,
  inputClasses,
  containerClasses,
  errorClasses,
  ...props
}) {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name)
  const [serverStatus, setServerStatus] = useState({ value: null, error: null })

  useEffect(
    () => {
      let isCurrent = true
      const { value, error } = callback?.[0]
        ? callback[0]()
        : { value: null, error: null }
      if (isCurrent && (value || value === "")) {
        setServerStatus(value)
        form.setFieldValue(field.name, value)
      } else if (isCurrent && error) {
        setServerStatus(null)
        form.setFieldValue(field.name, "")
      }

      return () => {
        isCurrent = false
        form.setFieldValue(field.name, "")
      }
    },
    callback?.[1] ? [...callback[1], callback[0], field.name] : []
  )

  return (
    <div
      className={`flex flex-col w-full text-black relative p-2 ${
        containerClasses ? containerClasses : ""
      }`}
    >
      <select
        className={`px-2 py-[9.5px] mt-[4px] mx-1 rounded focus:outline-none border-[1px] font-medium bg-[#fff] focus:border-4 focus:px-2 border-primary border-opacity-40 transition-all ${
          errorText ? "border-red-300 text-red-600" : "disabled:bg-zinc-200"
        } ${inputClasses ? inputClasses : ""}`}
        aria-label={field.name}
        disabled={!!serverStatus.value}
        {...props}
        {...field}
      >
        <option hidden value="">
          {props.label}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {errorText && (
        <p
          role="alert"
          htmlFor={field.name}
          className={`px-1 mt-2 text-xs text-red-600 ${
            errorClasses ? errorClasses : ""
          }`}
        >
          {errorText}
        </p>
      )}
    </div>
  )
}

SelectFormField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  callback: PropTypes.array,
  containerClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  errorClasses: PropTypes.string,
}
