import axios, { USERS_URL } from "./axios"

export const getUsers = () => {
  return axios.get(USERS_URL)
}

export const getUser = ({ queryKey }) => {
  const userId = queryKey[1]
  if (userId) return axios.get(`${USERS_URL}/${userId}`)
}

export const deleteUser = (userId) => {
  return axios.delete(`${USERS_URL}/${userId}`)
}

export const updateUser = ({ id, data }) => {
  let body = {
    user: {},
  }

  Object.entries(data).forEach((keyValuePair) => {
    let key = keyValuePair[0]
    let value = keyValuePair[1]
    return (body["user"][key] = value)
  })

  return axios.patch(`${USERS_URL}/${id}`, body)
}

export const createUser = ({ data }) => {
  let body = {
    user: {},
  }

  Object.entries(data).forEach((keyValuePair) => {
    let key = keyValuePair[0]
    let value = keyValuePair[1]
    return (body["user"][key] = value)
  })

  return axios.post(USERS_URL, body)
}
