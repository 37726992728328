import SuppliersSearch from "./SuppliersSearch"
import SuppliersTable from "./SuppliersTable"
import TableSkeleton from "../../components/skeletons/TableSkeleton"
import SuppliersErrorTable from "./SuppliersErrorTable"
import { useQuery } from "react-query"
import { getSuppliers } from "../../api/suppliersServices"
import { useMemo, useState, useEffect } from "react"

export default function Suppliers() {
  const {
    data: suppliers,
    isLoading,
    isError,
  } = useQuery(["suppliers"], getSuppliers)
  const [filter, setFilter] = useState("")
  const dataToPopulateTable = useMemo(() => {
    const data = suppliers?.data?.data
    if (filter === "" || !data) return data
    return data.filter((data) =>
      Object.values(data.attributes)
        .join(" ")
        .toLocaleLowerCase()
        .includes(filter.toLocaleLowerCase())
    )
  }, [filter, suppliers])

  const handleFilterChange = (valueSearchInput) => {
    setFilter(valueSearchInput.trim())
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  return (
    <main className="w-full flex flex-col justify-center items-center">
      <div className="max-w-6xl w-full flex flex-col px-4 pt-16">
        <SuppliersSearch handleFilterChange={handleFilterChange} />
        <div className="border-2 p-4 mt-16 text-black rounded-md shadow overflow-auto mb-8">
          <h1 className="text-3xl px-2 mb-2 font-semibold text-primary whitespace-nowrap">
            Fornecedores
          </h1>
          {isLoading ? (
            <TableSkeleton
              numberOfRows={10}
              numberOfHeads={3}
              numberOfDataTables={6}
            />
          ) : dataToPopulateTable?.length === 0 || isError ? (
            <SuppliersErrorTable />
          ) : (
            <SuppliersTable data={dataToPopulateTable} />
          )}
        </div>
      </div>
    </main>
  )
}
