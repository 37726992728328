import PropTypes from "prop-types"

export default function SkeletonElement(props) {
  const { type } = props
  const cssClass = `skeleton ${type}`

  return <div className={cssClass} />
}

SkeletonElement.propTypes = {
  type: PropTypes.oneOf(["text", "title", "avatar", "thumbnail", "field"])
    .isRequired,
}
