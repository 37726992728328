import { useMemo } from "react"
import { initialValues, validationSchema } from "./NFSeSchema"
import { Formik } from "formik"
import { useParams } from "react-router-dom"
import { getCompanies } from "../../api/NFSeServices"
import { getSuppliers } from "../../api/suppliersServices"
import NFSeForm from "./NFSeForm"
import { useMutation, useQuery } from "react-query"
import { getNFSe, postNFSe } from "../../api/NFSeServices"
import { selectReduceData } from "../../lib/utils"
import { ToastContainer, toast } from "react-toastify"
import NFSeLoading from "./NFSeLoading"

const toastConfig = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
}

export default function NFSeFormik() {
  const { id } = useParams()
  const { mutate } = useMutation(postNFSe, {
    onSuccess: (_, { resetForm, toastId, setSubmitting }) => {
      toast.update(toastId, {
        render: "NFSe enviada com sucesso!",
        type: "success",
        isLoading: false,
        ...toastConfig,
      })
      resetForm()
      setSubmitting(false)
      window.scrollTo({ top: 0, behavior: "smooth" })
    },
    onError: (data, { toastId, setSubmitting }) => {
      toast.update(toastId, {
        render: "Erro ao enviar NFSe, tente novamente!",
        type: "error",
        isLoading: false,
        ...toastConfig,
      })
      setSubmitting(false)
    },
  })
  const { data: nfse, isLoading: nfseLoading } = useQuery(["nfse", id], getNFSe)
  const nfseData = useMemo(() => {
    const nfseAttributes = nfse?.data?.data?.attributes
    if (!nfseAttributes) return
    return Object.entries(nfseAttributes).reduce((total, [key, value]) => {
      key = key.replaceAll("-", "_")
      if (!value) value = ""
      if (key === "data_emissao")
        value = new Date(value).toISOString().split("T")[0] //yyyy-MM-dd
      total[key] = value
      return total
    }, {})
  }, [nfse])

  const { data: companies, isLoading: companiesLoading } = useQuery(
    ["companies"],
    getCompanies
  )
  const companiesData = useMemo(() => {
    const companiesData = companies?.data?.data
    if (!companiesData || companiesData.lenght === 0) return []
    return selectReduceData({
      data: companiesData,
      attributeName: "razao-social",
    })
  }, [companies])

  const { data: suppliers, isLoading: supplierLoading } = useQuery(
    ["suppliers"],
    getSuppliers
  )

  const handleSubmit = ({ values, resetForm, setSubmitting }) => {
    setSubmitting(true)
    const toastId = toast.loading("Enviando NFSe...", toastConfig)
    mutate({ NFSeData: values, resetForm, toastId, setSubmitting })
  }
  
  if (nfseLoading || supplierLoading || companiesLoading) return <NFSeLoading />

  return (
    <>
      <Formik
        initialValues={nfseData || initialValues}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm, setSubmitting }) =>
          handleSubmit({ values, resetForm, setSubmitting })
        }
      >
        {(props) => {
          return (
            <NFSeForm
              nfse={!!nfseData}
              suppliers={suppliers}
              companiesData={companiesData}
              {...props}
            />
          )
        }}
      </Formik>
      <ToastContainer />
    </>
  )
}
