export default function NfseNoNotes() {
  return (
    <table data-testid="nfse-error-table" className="w-full text-left">
      <thead className="border-b-2 border-black whitespace-nowrap">
        <tr>
          <th className="p-2 text-sm font-semibold tracking-wide">
            Data de Emissão
          </th>
          <th className="p-2 text-sm font-semibold tracking-wide">
            Fornecedor
          </th>
          <th className="p-2 text-sm font-semibold tracking-wide">Número</th>
          <th className="p-2 text-sm font-semibold tracking-wide">Valor</th>
        </tr>
      </thead>
      <tbody className="whitespace-nowrap">
        <tr>
          <td className="text-center pt-12 pb-4 font-bold text-3xl" colSpan="4">
            Nenhuma nota encontrada!
          </td>
        </tr>
        <tr>
          <td className="text-center pb-8 font-bold" colSpan="4">
            Mude os filtros e tente novamente.
          </td>
        </tr>
      </tbody>
    </table>
  )
}
