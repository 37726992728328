import { useLocation, useParams } from "react-router-dom"
import { useEffect } from "react"
import NewUserForm from "./NewUserForm"
import { ToastContainer } from "react-toastify"

export default function NewUser() {
  const { search: queryString } = useLocation()
  const params = new URLSearchParams(queryString)
  const supplierId = params.get("supplier_id")
  const { id: userId } = useParams()

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  return (
    <main className="w-full flex flex-col justify-center items-center">
      <div className="max-w-7xl w-full flex flex-col px-4 pt-8">
        <div className="p-4 shadow-md text-center w-full border-2 rounded-md">
          <h1 className="text-4xl text-primary font-medium">
            Cadastro de Usuário
          </h1>
        </div>
        <div className="justify-center items-center w-full border-2 rounded-md p-4 my-8">
          <NewUserForm supplierId={supplierId} userId={userId} />
          <ToastContainer />
        </div>
      </div>
    </main>
  )
}
