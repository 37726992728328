export default function NFSeHeader() {
  return (
    <header className="flex justify-center shadow-lg p-6 w-full rounded-md border-2">
      <div className="text-2xl text-center font-medium text-gray-500">
        <span className="text-primary">NFSe&nbsp;</span>- Nota Fiscal de Serviço
        Eletrônica
      </div>
    </header>
  )
}
