import NFSeHeader from "./NFSeHeader"
import NFSeFormik from "./NFSeFormik"
import { useEffect } from "react"

export default function Nfse() {
  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  return (
    <main className="w-full flex justify-center items-center">
      <div className="w-full max-w-7xl flex flex-col items-center pt-8 px-4">
        <NFSeHeader />
        <NFSeFormik />
      </div>
    </main>
  )
}
