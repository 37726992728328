import UsersTableRow from "./UsersTableRow"

export default function UsersTable({ data: users, getSupplierWithId }) {
  return (
    <table data-testid="actual-table" className="w-full text-left">
      <thead className="border-b-2 border-black whitespace-nowrap">
        <tr>
          <th className="p-2 text-sm font-semibold tracking-wide">Nome</th>
          <th className="p-2 text-sm font-semibold tracking-wide">E-mail</th>
          <th className="p-2 text-sm font-semibold tracking-wide">
            Fornecedor
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-zinc-300 text-zinc-700 whitespace-nowrap">
        {users?.map((user) => (
          <UsersTableRow
            key={user.id}
            getSupplierWithId={getSupplierWithId}
            user={user}
          />
        ))}
      </tbody>
    </table>
  )
}
