import { useNavigate } from "react-router-dom"

export default function NoMatch() {
  const navigate = useNavigate()

  const goBack = () => {
    navigate("/")
  }

  return (
    <main className="bg-primary min-h-screen -mt-20 flex flex-col justify-center items-center w-full text-center">
      <div>
        <img alt="error 404" className="pt-20" src="404_focus_nfe.png" />
      </div>

      <h1 className="text-5xl font-medium text-zinc-100 pt-8 pb-4 px-4">
        Página não encontrada
      </h1>
      <h2
        onClick={goBack}
        className="text-3xl font-bold text-zinc-100 px-4 hover:text-zinc-300 cursor-pointer"
      >
        Voltar
      </h2>
    </main>
  )
}
