export default function NfseNoNotes() {
  return (
    <table data-testid="error-table" className="w-full text-left">
      <thead className="border-b-2 border-black whitespace-nowrap">
        <tr>
          <th className="p-2 text-sm font-semibold tracking-wide">Nome</th>
          <th className="p-2 text-sm font-semibold tracking-wide">Documneto</th>
          <th className="p-2 text-sm font-semibold tracking-wide">
            Município/UF
          </th>
        </tr>
      </thead>
      <tbody className="whitespace-nowrap">
        <tr>
          <td className="text-center pt-12 pb-4 font-bold text-3xl" colSpan="4">
            Nenhum usuário encontrado!
          </td>
        </tr>
      </tbody>
    </table>
  )
}
