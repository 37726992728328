import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import TableSkeleton from "../../components/skeletons/TableSkeleton"
import NfseSearch from "./NfseSearch"
import NfseTable from "./NfseTable"
import NfseNoNotes from "./NfseNoNotes"
import { getAllNFSe } from "../../api/NFSeServices"

export default function Home() {
  const [filters, setFilters] = useState("")
  const {
    data: notas_nfse,
    isLoading,
    isError,
  } = useQuery(["notas_nfse", filters], getAllNFSe)

  const handleFilterChange = ({
    chosenOrderId,
    chosenSupplierId,
    searchTerm,
  }) => {
    let queryString = ""
    if (chosenSupplierId && chosenSupplierId !== "TODOS")
      queryString += `&supplier_id=${chosenSupplierId}`
    if (chosenOrderId && chosenOrderId !== "TODOS")
      queryString += `&order_id=${chosenOrderId}`
    if (searchTerm && searchTerm !== "") queryString += `&search=${searchTerm}`
    setFilters(queryString)
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  return (
    <main className="w-full flex flex-col justify-center items-center">
      <div className="max-w-6xl w-full flex flex-col px-4">
        <NfseSearch handleFilterChange={handleFilterChange} />
        <div className="border-2 p-4 text-black rounded-md shadow overflow-auto mb-8">
          <h1 className="text-3xl px-2 mb-2 font-semibold text-primary whitespace-nowrap">
            Notas Fiscais de Serviço Eletrônica
          </h1>
          {isLoading ? (
            <TableSkeleton
              numberOfRows={10}
              numberOfHeads={4}
              numberOfDataTables={6}
            />
          ) : notas_nfse?.data?.data?.length === 0 || isError ? (
            <NfseNoNotes />
          ) : (
            <NfseTable notas_nfse={notas_nfse?.data} />
          )}
        </div>
      </div>
    </main>
  )
}
