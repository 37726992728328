import * as Yup from "yup"

export const OPERATION_NATURE_VALUES = [
  "Tributação no município",
  "Tributação fora do município",
  "Isenção",
  "Imune",
  "Exigibilidade suspensa por decisão judicial",
  "Exigibilidade suspensa por procedimento administrativo",
].reduce((total, current) => {
  const label = current
  const value = current
  return [...total, { value, label }]
}, [])

export const validationSchema = Yup.object().shape(
  {
    supplier_id: Yup.string()
      .required("Por favor, escolha um fornecedor.")
      .default(""),
    company_id: Yup.string()
      .required("Por favor, escolha uma empresa.")
      .default(""),
    order_id: Yup.string().default(""),
    data_emissao: Yup.string()
      .required("Por favor, informe a data de emissão.")
      .default(""),
    numero: Yup.string().required("Por favor, informe o número.").default(""),
    codigo_verificacao: Yup.string()
      .required("Por favor, informe o código de verificação.")
      .default(""),
    natureza_operacao: Yup.string().default(""),
    numero_rps: Yup.string().default(""),
    serie_rps: Yup.string().default(""),
    url: Yup.string().required("Por favor, informe a URL.").default(""),
    url_danfse: Yup.string().default(""),
    discriminacao: Yup.string()
      .required("Por favor, informe a discriminação.")
      .default(""),
    codigo_item_lista_servico_obrigatorio: Yup.bool().default(true),
    codigo_item_lista_servico: Yup.string()
      .default("")
      .when("codigo_item_lista_servico_obrigatorio", {
        is: (value) => value === true,
        then: Yup.string()
          .default("")
          .required("Por favor, informe o serviço."),
      }),
    codigo_tributario_municipio_obrigatorio: Yup.bool().default(true),
    codigo_tributario_municipio: Yup.string()
      .default("")
      .when("codigo_tributario_municipio_obrigatorio", {
        is: (value) => value === true,
        then: Yup.string()
          .default("")
          .required("Por favor, informe o código tributário."),
      }),
    codigo_cnae_obrigatorio: Yup.bool().default(true),
    codigo_cnae: Yup.string()
      .default("")
      .when("codigo_cnae_obrigatorio", {
        is: (value) => value === true,
        then: Yup.string()
          .default("")
          .required("Por favor, informe o código CNAE."),
      }),
    aliquota: Yup.string().default(""),
    valor_iss: Yup.string().default(""),
    iss_retido: Yup.bool().default(false),
    valor_iss_retido: Yup.string()
      .default("")
      .when("iss_retido", {
        is: (value) => value === true,
        then: Yup.string()
          .default("")
          .required("Por favor, informe o valor retido."),
      }),
    valor_deducoes: Yup.string().default(""),
    valor_pis: Yup.string().default(""),
    valor_cofins: Yup.string().default(""),
    valor_inss: Yup.string().default(""),
    valor_ir: Yup.string().default(""),
    valor_csll: Yup.string().default(""),
    status: Yup.string().default(""),
    outras_retencoes: Yup.string().default(""),
    desconto_incondicionado: Yup.string().default(""),
    desconto_condicionado: Yup.string().default(""),
    regime_especial_tributacao: Yup.string().default(""),
    incentivo_cultural: Yup.string().default(""),
    optante_simples_nacional: Yup.string().default(""),
    percentual_total_tributos: Yup.string().default(""),
    valor_total_tributos: Yup.string().default(""),
    valor_liquido: Yup.string().default(""),
    valor_servicos: Yup.string()
      .required("Por favor, informe o valor da nota.")
      .default(""),
    dataAuthenticity: Yup.boolean().default(false).isTrue().required(""),
  },
  [
    "codigo_item_lista_servico_obrigatorio",
    "codigo_tributario_municipio_obrigatorio",
    "codigo_cnae_obrigatorio",
    "iss_retido",
  ]
)

export const initialValues = Object.entries(validationSchema.fields).reduce(
  (total, currentValue) => {
    const name = currentValue[0]
    let defaultValue = currentValue[1].spec.default
    total[name] = defaultValue
    return total
  },
  {}
)
