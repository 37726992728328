import { useState } from "react"
import TextFormField from "../../components/forms/TextFormField"
import { Field } from "formik"

export default function NFSeFormAdditionalInfo({ isDisabled }) {
  const [menuOpened, setMenuOpened] = useState(false)

  return (
    <>
      <div
        onClick={() => setMenuOpened((prevState) => !prevState)}
        className="pt-4 mt-6 flex w-fit items-center space-x-1 hover:scale-105 transition-all cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-primary"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
        </svg>
        <p className="text-primary pl-1 font-semibold">
          Informar Retenções e Descontos
        </p>
      </div>
      <div
        className={`w-full rounded-md mt-2 md:p-4 border-2 transition-all ${
          menuOpened
            ? "opacity-100 mb-12"
            : "h-0 p-0 border-0 opacity-0 pointer-events-none mb-0"
        }`}
      >
        <div className="grid grid-cols-1 sm:grid-cols-3">
          <Field
            disabled={isDisabled}
            name="valor_deducoes"
            label="Valor deduções"
            component={TextFormField}
            type="number"
          />
          <Field
            disabled={isDisabled}
            name="valor_pis"
            label="Valor PIS"
            component={TextFormField}
            type="number"
          />
          <Field
            disabled={isDisabled}
            name="valor_cofins"
            label="Valor COFINS"
            component={TextFormField}
            type="number"
          />
          <Field
            disabled={isDisabled}
            name="valor_inss"
            label="Valor INSS"
            component={TextFormField}
            type="number"
          />
          <Field
            disabled={isDisabled}
            name="valor_ir"
            label="Valor IR"
            component={TextFormField}
            type="number"
          />
          <Field
            disabled={isDisabled}
            name="valor_csll"
            label="Valor CSLL"
            component={TextFormField}
            type="number"
          />
          <Field
            disabled={isDisabled}
            name="outras_retencoes"
            label="Outras retenções"
            component={TextFormField}
            type="number"
          />
          <Field
            disabled={isDisabled}
            name="desconto_incondicionado"
            label="Desconto incondicionado"
            component={TextFormField}
            type="number"
          />
          <Field
            disabled={isDisabled}
            name="desconto_condicionado"
            label="Desconto condicionado"
            component={TextFormField}
            type="number"
          />
        </div>
      </div>
    </>
  )
}
