import { useState, useMemo, useCallback, useEffect } from "react"
import { Formik, Form, Field } from "formik"
import { shuffleArray, getAllChars } from "../../lib/utils"
import { validationSchema, initialValues } from "./newUserSchema"
import TextFormField from "../../components/forms/TextFormField"
import SelectFormField from "../../components/forms/SelectFormField"
import CheckboxFormField from "../../components/forms/CheckboxFormField"
import { createUser, getUser, updateUser } from "../../api/usersServices"
import { useQuery } from "react-query"
import { getSuppliers, getSupplier } from "../../api/suppliersServices"
import NewUserFormLoading from "./NewUserFormLoading"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

const toastConfig = {
  position: "bottom-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
}

export default function NewUserForm({ supplierId, userId }) {
  const navigate = useNavigate()
  const [password, setPassword] = useState("")
  const [copiedToClipboard, setCopiedToClipboard] = useState(false)
  const { data: suppliers, isLoading: suppliersLoading } = useQuery(
    ["suppliers", supplierId],
    supplierId ? getSupplier : getSuppliers
  )
  const suppliersData = useMemo(() => {
    let data = suppliers?.data?.data
    if (!data) return []
    if (!data.length) data = Array(data)

    return data.map((supplier) => {
      return {
        label: supplier?.attributes?.["razao-social"],
        value: supplier?.id,
      }
    })
  }, [suppliers])

  const normalizeUserAttributes = (userAttributes) => {
    let data = Object.entries(userAttributes).reduce((total, keyValuePair) => {
      let key = keyValuePair[0]
      let value = keyValuePair[1]
      key = key.replaceAll("-", "_")

      if (!value) value = ""
      total[key] = value

      return total
    }, {})
    data["password"] = ""
    data["editing_mode"] = true
    return data
  }

  const { data: user, isLoading: userLoading } = useQuery(
    ["users", userId],
    getUser
  )
  const userData = useMemo(() => {
    const userAttributes = user?.data?.data?.attributes
    if (userAttributes) return normalizeUserAttributes(userAttributes)
  }, [user])

  const serverErrorToMessage = (error) => {
    if (error.email) return "Email já em uso por outro Usuário."
    if (error.name) return "Nome já em uso por outro Usuário."
    return "Um erro inesperado aconteceu, tente novamante!"
  }

  const handleSubmit = async ({ setSubmitting, values, resetForm }) => {
    const toastId = toast.loading("Validando o formulário...", toastConfig)
    setSubmitting(true)
    let data = { ...values, password }
    try {
      if (user) await updateUser({ data, id: userId })
      else {
        await createUser({ data })
        resetForm()
        setPassword("")
      }
      toast.update(toastId, {
        render: userData
          ? "Mudanças salvas com sucesso!"
          : "Usuário criado com sucesso!",
        type: "success",
        isLoading: false,
        ...toastConfig,
      })
    } catch (e) {
      const error = e.response.data
      toast.update(toastId, {
        render: serverErrorToMessage(error),
        type: "error",
        isLoading: false,
        ...toastConfig,
      })
    } finally {
      setSubmitting(false)
    }
  }

  const passwordCallback = useCallback(() => {
    return { value: password, error: null }
  }, [password])

  const supplierCallback = useCallback(() => {
    if (suppliersData.length === 1 && !userData)
      return { value: suppliersData[0].value }
    if (userData) return { value: userData.supplier_id, error: null }
    return { value: null, error: null }
  }, [userData, suppliersData])

  const editingUserCallback = useCallback(() => {
    if (userData) return { value: true, error: null }
    return { value: false, error: null }
  }, [userData])

  const generateNewPassword = () => {
    const allChars = getAllChars()
    let randomPasswordArray = Array(18)
    randomPasswordArray = randomPasswordArray.fill(allChars, 4)
    setPassword(
      shuffleArray(
        randomPasswordArray.map(function (x) {
          return x[Math.floor(Math.random() * x.length)]
        })
      ).join("")
    )
  }

  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text)
    setCopiedToClipboard(true)
  }

  useEffect(() => {
    let timer
    if (copiedToClipboard === true) {
      timer = setTimeout(() => {
        setCopiedToClipboard(false)
      }, 2000)
    }
    return () => clearTimeout(timer)
  }, [copiedToClipboard])

  const goToUsersPage = () => {
    navigate(`/users`)
  }

  if (suppliersLoading || userLoading) return <NewUserFormLoading />

  return (
    <Formik
      initialValues={userData || initialValues}
      enableReinitialize={true}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm, setSubmitting }) =>
        handleSubmit({ values, resetForm, setSubmitting })
      }
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <p
              onClick={goToUsersPage}
              className="underline w-fit px-4 mb-2 text-xs text-primary hover:text-blue-400 cursor-pointer"
            >
              Voltar para a página de usuários
            </p>
            <Field
              callback={[supplierCallback, [userData]]}
              options={suppliersData}
              label="Fornecedor*"
              name="supplier_id"
              component={SelectFormField}
            />
            <div className="grid sm:grid-cols-2">
              <Field label="Nome*" name="name" component={TextFormField} />
              <Field label="Email*" name="email" component={TextFormField} />
            </div>
            <div className="grid sm:grid-cols-2 items-center">
              <div className="flex items-center">
                <Field
                  callback={[passwordCallback, [password]]}
                  disabled={true}
                  label="Senha*"
                  name="password"
                  size={4}
                  component={TextFormField}
                />
                <div
                  className="cursor-pointer relative"
                  onClick={() => copyToClipboard(password)}
                >
                  {copiedToClipboard ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 hover:scale-125 text-green-600 transition-all peer"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                      <path
                        fillRule="evenodd"
                        d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 hover:scale-125 text-black transition-all peer"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                      />
                    </svg>
                  )}
                  <span className="absolute w-auto -top-12 shadow-lg transition-all sm:-translate-x-1/2 sm:left-1/2 left-full -translate-x-full p-2 min-w-max z-10 bg-white border-2 text-primary border-zinc-200 text-sm sm:origin-center origin-right rounded-lg scale-0 peer-hover:scale-100">
                    Clique para copiar a senha 💡
                  </span>
                </div>
              </div>
              <div className="flex items-center justify-self-end mx-3">
                <button
                  type="button"
                  onClick={generateNewPassword}
                  className="button cursor-pointer m-0"
                >
                  Gerar nova senha forte
                </button>
              </div>
            </div>
            <div className="hidden">
              <Field
                callback={[editingUserCallback, [userData]]}
                name="editing_mode"
                component={CheckboxFormField}
              />
            </div>
            <button
              disabled={isSubmitting}
              type="submit"
              className="button mt-1 mx-3"
            >
              Salvar
            </button>
          </Form>
        )
      }}
    </Formik>
  )
}
