import { useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"
import { deleteSupplier } from "../../api/suppliersServices"
import { documentMask } from "../../lib/masks"

export default function SuppliersTableRow({ supplier }) {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [isModalOpened, setIsModalOpened] = useState(false)
  const supplierId = supplier?.id
  const { mutate: mutateDeleteSupplier } = useMutation(deleteSupplier, {
    onSuccess: () => {
      queryClient.invalidateQueries(["suppliers"])
    },
  })

  const prettifyDocumentField = () => {
    const cnpj = supplier.attributes.cnpj
    const cpf = supplier.attributes.cpf

    if (cnpj) return documentMask(cnpj)
    if (cpf) return documentMask(cpf)
    return "Sem identificação"
  }

  const handleOpenModal = () => {
    setIsModalOpened(true)
  }

  const handleDelete = () => {
    mutateDeleteSupplier(supplierId)
    setIsModalOpened(false)
  }

  const cancelDeleteAction = () => {
    setIsModalOpened(false)
  }

  const goToVisualizeSupplier = () => {
    navigate(`/suppliers/${supplierId}`)
  }

  const goToNewUser = () => {
    navigate(`/users/new?supplier_id=${supplierId}`)
  }

  return (
    <tr className="even:bg-gray-100 hover:bg-gray-200 transition-all">
      <td className="px-2 py-3 text-sm max-w-[188px] ellipsis">
        {supplier?.attributes?.["razao-social"]}
      </td>
      <td className="px-2 py-3 text-sm">{prettifyDocumentField()}</td>
      <td className="px-2 py-3 text-sm max-w-[96px] ellipsis">
        {supplier?.attributes?.municipio}/{supplier?.attributes?.uf}
      </td>
      <td className="text-right w-64">
        <button
          onClick={goToVisualizeSupplier}
          className="inline-block px-2 mr-2 py-1 w-fit self-end text-sm rounded-md bg-primary hover:bg-blue-700 transition-all hover:shadow-md text-[#fff]"
        >
          Visualizar
        </button>
        <button
          onClick={goToNewUser}
          className="inline-block px-2 mr-2 py-1 w-fit self-end text-sm rounded-md bg-primary hover:bg-blue-700 transition-all hover:shadow-md text-[#fff]"
        >
          Novo usuário
        </button>
        <svg
          onClick={handleOpenModal}
          data-testid="delete-icon"
          xmlns="http://www.w3.org/2000/svg"
          className={`h-6 w-6 mb-1 inline-block text-red-400 hover:text-red-600 cursor-pointer transition-all`}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
          />
        </svg>
      </td>
      {isModalOpened && (
        <td
          onClick={cancelDeleteAction}
          className="fixed flex justify-center items-center h-screen w-screen z-20 bg-black bg-opacity-30 top-0 left-0 whitespace-normal"
        >
          <div className="bg-zinc-100 p-8 rounded w-fit flex flex-col mx-4">
            <h3 className="text-center pb-3 text-2xl text-black break-words">
              Tem certeza que deseja excluir o Fornecedor "
              {supplier?.attributes?.["razao-social"]}"?
            </h3>
            <div className="text-center">
              <button
                onClick={handleDelete}
                className="bg-red-100 m-2 p-2 text-red-800 rounded border-[1px] border-red-200 transition-all hover:bg-red-300"
              >
                Excluir Fornecedor
              </button>
              <button
                onClick={cancelDeleteAction}
                className="m-2 p-2 bg-green-100 text-green-800 rounded-md border-[1px] border-green-200 transition-all hover:bg-green-300"
              >
                Cancelar
              </button>
            </div>
          </div>
        </td>
      )}
    </tr>
  )
}
