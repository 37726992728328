/* eslint-disable */
import { useState, useRef, useCallback, useEffect, useMemo } from "react"
import PropTypes from "prop-types"
import { getIn } from "formik"
import TextFormField from "./TextFormField"

export default function AutoCompleteFormField({
  field,
  form,
  mask,
  callback,
  options,
  inputClasses,
  containerClasses,
  labelClasses,
  errorClasses,
  ...props
}) {
  const handleChangeWithMask = useCallback(
    (e) => {
      const value = mask(e.target.value)
      form.setFieldValue(field.name, value)
    },
    [mask, field.name, form]
  )
  const containerRef = useRef()
  const [opened, setOpened] = useState(false)
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name)
  const currentValue = getIn(form.values, field.name)
  const [serverStatus, setServerStatus] = useState(null)

  const handleValueClick = (value) => {
    form.setFieldValue(field.name, value)
    setOpened(false)
  }

  const autoCompleteOptions = useMemo(() => {
    if (!options) return []
    return options.filter(({ label }) =>
      label.toLowerCase().includes(currentValue.trim().toLowerCase())
    )
  }, [currentValue, options])

  const handleClickOutside = useCallback(
    (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setOpened(false)
      }
    },
    [containerRef]
  )

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [handleClickOutside])

  useEffect(
    () => {
      let isCurrent = true
      const { value, error } = callback?.[0]
        ? callback[0]()
        : { value: null, error: null }
      if (isCurrent && (value || value === "")) {
        setServerStatus(value)
        form.setFieldValue(field.name, value)
      } else if (isCurrent && error) {
        setServerStatus(null)
        form.setFieldValue(field.name, "")
      }

      return () => {
        isCurrent = false
        form.setFieldValue(field.name, "")
      }
    },
    callback?.[1] ? [...callback[1], callback[0]] : []
  )

  useEffect(() => {
    const valueInArray = (currentValue) => {
      if (!options) return false
      return options.find(({ label }) => currentValue === label)
    }
    const currentValue = getIn(form.values, field.name)
    if (!valueInArray(currentValue) && !props.disabled)
      form.setFieldValue(field.name, "")
  }, [opened])

  if (!options || options.length === 0)
    return (
      <TextFormField
        callback={callback}
        mask={mask}
        field={field}
        form={form}
        {...props}
      />
    )

  return (
    <div
      ref={containerRef}
      className={`flex flex-col text-black relative m-3 ${
        containerClasses ? containerClasses : ""
      }`}
    >
      <input
        autoComplete="nope"
        onFocus={() => setOpened(true)}
        size={8}
        className={`peer rounded px-3 py-2 outline-none border-[1px] font-medium pr-8 text-ellipsis border-primary border-opacity-40 ${
          opened && "border-b-0 rounded-b-none"
        } ${
          errorText && !serverStatus
            ? "border-red-300 text-red-600"
            : "disabled:bg-zinc-200"
        } ${inputClasses ? inputClasses : ""}`}
        type="text"
        aria-label={field.name}
        {...field}
        {...props}
        onBlur={() => {
          setTimeout(() => setOpened(false), 100)
          return field.onBlur
        }}
        onChange={mask ? handleChangeWithMask : field.onChange}
      />
      {currentValue && (
        <>
          <div
            onClick={() => form.setFieldValue(field.name, "")}
            className="absolute h-full flex place-self-end pr-2 mt-3 cursor-pointer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </div>
        </>
      )}
      <label
        htmlFor={field.name}
        className={`absolute pointer-events-none mx-2 px-1 translate-y-[9px] peer-focus:text-sm max-w-[calc(100%-(2*8px))] ellipsis ${
          currentValue !== "" ? "-translate-y-[10px] bg-white text-sm" : ""
        } rounded transition-all ${
          opened
            ? "-translate-y-[10px] bg-white text-sm"
            : "peer-focus:-translate-y-[10px] peer-focus:bg-white"
        } ${
          errorText && !serverStatus
            ? "text-red-600"
            : "peer-focus:text-zinc-600"
        } ${labelClasses ? labelClasses : ""}`}
      >
        {props.label}
      </label>
      {errorText && !serverStatus && (
        <p
          role="alert"
          htmlFor={field.name}
          className={`px-1 text-xs text-red-600 mt-2 ${
            errorClasses ? errorClasses : ""
          }`}
        >
          {errorText}
        </p>
      )}
      {opened && (
        <>
          <ul
            className={`absolute translate-y-[41px] max-h-60 z-50 overflow-y-auto overflow-x-hidden w-full bg-white border-[1px] border-t-0 rounded-b border-primary border-opacity-40 shadow-lg ${
              errorText && !serverStatus ? "border-red-300" : ""
            }`}
          >
            {autoCompleteOptions.map((option, index) => (
              <li
                onClick={() => handleValueClick(option.label)}
                key={index}
                className="p-2 break-words hover:bg-zinc-200 transition-all cursor-pointer"
              >
                {option.label}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}

AutoCompleteFormField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  mask: PropTypes.func,
  callback: PropTypes.array,
  label: PropTypes.string,
  maxLength: PropTypes.string,
  containerClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  errorClasses: PropTypes.string,
}
