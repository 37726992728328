import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"

if (
  process.env.REACT_APP_API_MOCKING === "enabled" &&
  process.env.NODE_ENV !== "test"
) {
  require("./lib/mocks/mocks")
}

const root = ReactDOM.createRoot(document.getElementById("portal-focus-nfse"))
root.render(<App />)
