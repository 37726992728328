import { useState } from "react"
import useAuth from "../../contexts/authContext"

export default function Login() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [isPasswordFocused, setIsPasswordFocused] = useState(false)
  const [isEmailFocused, setIsEmailFocused] = useState(false)
  const { logIn, error, loginInProgress } = useAuth()

  const handleSubmit = (e) => {
    e.preventDefault()
    logIn({ email, password })
  }

  return (
    <main className="flex flex-col w-full min-h-screen items-center bg-[#F8F9FA]">
      <header className="sticky top-0 left-0 flex justify-center items-center p-3 w-full border-b-[5px] bg-[#fff] border-primary shadow-xl">
        <img alt="logo" width={132} src="logo_focus_nfe.png" />
      </header>
      <div className="flex flex-col bg-[#fff] shadow-2xl border-[1px] rounded-xl m-4 my-12">
        <div className="p-6 shadow-sm shadow-gray-200">
          <h1 className="text-primary tracking-widest text-sm">
            PORTAL DE ENTRADA DE NFSE
          </h1>
        </div>
        <form className="p-6 relative">
          <h1 className="font-bold text-black text-2xl pb-3">
            Olá, seja bem-vindo(a)
          </h1>
          <p className="text-sm text-zinc-500">
            Utilize os campos abaixo para fazer login no portal de entrada de
            NFSe
          </p>
          <div className="flex flex-col">
            <span
              className={`input-span-login ${
                isEmailFocused || email
                  ? "translate-y-[32px] text-xs text-zinc-600"
                  : ""
              }`}
            >
              Endereço de email
            </span>
            <input
              type="text"
              autoComplete="off"
              value={email}
              data-testid="email-input"
              onChange={(e) => setEmail(e.target.value)}
              onFocus={() => setIsEmailFocused(true)}
              onBlur={() => setIsEmailFocused(false)}
              size={10}
              className="input-login"
              required
            />
            <span
              className={`input-span-login ${
                isPasswordFocused || password
                  ? "translate-y-[32px] text-xs text-zinc-600"
                  : ""
              }`}
            >
              Senha
            </span>
            <input
              type="password"
              autoComplete="off"
              value={password}
              data-testid="password-input"
              onChange={(e) => setPassword(e.target.value)}
              onFocus={() => setIsPasswordFocused(true)}
              onBlur={() => setIsPasswordFocused(false)}
              size={10}
              className="input-login"
              required
            />
            {loginInProgress && (
              <p
                data-testid="loading"
                className="p-2 px-3 text-sm bg-green-100 text-green-800 rounded-md border-[1px] border-green-200"
              >
                Verificando credenciais...
              </p>
            )}
            {error && !loginInProgress && (
              <p
                data-testid="error"
                className="p-2 px-3 text-sm bg-red-100 text-red-800 rounded-md border-[1px] border-red-200"
              >
                {error}
              </p>
            )}
            <button
              disabled={!email || !password}
              onClick={handleSubmit}
              className="button"
            >
              Entrar
            </button>
          </div>
          <p className="text-sm text-zinc-500 mt-4 transition-all">
            Não tem uma conta? Entre em contato com o administrador.
          </p>
        </form>
      </div>
    </main>
  )
}
