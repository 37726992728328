import axios, {
  COMPANIES_URL,
  ORDERS_URL,
  CNAECODES_URL,
  COUNTIES_URL,
  NOTA_NFSE_URL,
  NOTAS_NFSE_URL,
} from "./axios"

export const getCNAECode = ({ queryKey }) => {
  const CNAEId = queryKey[1]
  if (CNAEId) return axios.get(`${CNAECODES_URL}/${CNAEId}`)
}

export const getCompanies = () => {
  return axios.get(COMPANIES_URL)
}

export const getCNAECodes = () => {
  return axios.get(CNAECODES_URL)
}

export const getOrders = ({ queryKey }) => {
  let queryString = ""
  const supplierId = queryKey[1]
  if (supplierId && supplierId !== "" && supplierId !== "TODOS")
    queryString += `?supplier_id=${supplierId}`
  return axios.get(`${ORDERS_URL}${queryString}`)
}

export const getCountyRules = ({ queryKey }) => {
  const countyCode = queryKey[1]
  if (countyCode) return axios.get(`${COUNTIES_URL}/${countyCode}`)
}

export const getTaxCodes = ({ queryKey }) => {
  const countyCode = queryKey[1]
  if (countyCode)
    return axios.get(
      `${COUNTIES_URL}/${countyCode}/codigos_tributarios_municipio`
    )
}

export const getServiceItems = ({ queryKey }) => {
  const countyCode = queryKey[1]
  if (countyCode)
    return axios.get(`${COUNTIES_URL}/${countyCode}/itens_lista_servico`)
}

export const getNFSe = ({ queryKey }) => {
  const id = queryKey[1]
  if (id) return axios.get(`${NOTA_NFSE_URL}/${id}`)
}

export const getAllNFSe = ({ queryKey }) => {
  const filters = queryKey[1]
  return axios.get(`${NOTAS_NFSE_URL}${filters}`)
}

export const postNFSe = ({ NFSeData }) => {
  let { dataAuthenticity, ...data } = NFSeData

  let body = {
    nota_fiscal_servico: {},
  }

  Object.entries(data).forEach((keyValuePair) => {
    let key = keyValuePair[0]
    let value = keyValuePair[1]
    if (key.split("_")[key.split("_").length - 1] === "obrigatorio") return
    if (key === "order_id") return
    body["nota_fiscal_servico"][key] = value
  })

  return axios.post(NOTA_NFSE_URL, body)
}
