const isCNPJBase = (cnpj) => {
  if (cnpj.length !== 14) return false
  if (/0{14}/.test(cnpj)) return false
  return true
}

const isCPFBase = (cpf) => {
  if (cpf.length !== 11) return false
  if (/0{11}/.test(cpf)) return false
  return true
}

const verifyFirstDigitCNPJ = (cnpj) => {
  const multipliers = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
  for (var i = 0, total = 0; i < 12; total += cnpj[i] * multipliers[++i]) {}
  if (Number(cnpj[12]) !== ((total %= 11) < 2 ? 0 : 11 - total)) return false
  return true
}

const verifySecondDigitCNPJ = (cnpj) => {
  const multipliers = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
  for (var i = 0, total = 0; i <= 12; total += cnpj[i] * multipliers[i++]) {}
  if (Number(cnpj[13]) !== ((total %= 11) < 2 ? 0 : 11 - total)) return false
  return true
}

const verifyFirstDigitCPF = (cpf) => {
  for (var i = 1, sum = 0; i <= 9; i++)
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i)
  var rest = (sum * 10) % 11
  if (rest === 10 || rest === 11) rest = 0
  if (rest !== parseInt(cpf.substring(9, 10))) return false
  return true
}

const verifySecondDigitCPF = (cpf) => {
  for (var i = 1, sum = 0; i <= 10; i++)
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i)
  var rest = (sum * 10) % 11
  if (rest === 10 || rest === 11) rest = 0
  if (rest !== parseInt(cpf.substring(10, 11))) return false
  return true
}

const verifyRepeatedNumbers = (value) => {
  const repeatedNumbersRegex = /^(\d)\1+$/
  return repeatedNumbersRegex.test(value)
}

export const cleanDocumentString = (document) => {
  document = String(document).replace(/[^\d]/g, "")
  document = String(document).replaceAll('"', "")
  return document
}

export const validateCPF = (cpf) => {
  cpf = cleanDocumentString(cpf)
  if (verifyRepeatedNumbers(cpf)) return false
  if (!isCPFBase(cpf)) return false
  if (!verifyFirstDigitCPF(cpf)) return false
  if (!verifySecondDigitCPF(cpf)) return false

  return true
}

export const validateCNPJ = (cnpj) => {
  cnpj = cleanDocumentString(cnpj)
  if (!isCNPJBase(cnpj)) return false
  if (!verifyFirstDigitCNPJ(cnpj)) return false
  if (!verifySecondDigitCNPJ(cnpj)) return false

  return true
}
