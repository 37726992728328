import React, { useState } from "react"
import { useNavigate } from "react-router-dom"
import NfseSearchFilters from "./NfseSearchFilters"

export default function NfseSearch({ handleFilterChange }) {
  const [searchTerm, setSearchTerm] = useState("")
  const [chosenSupplierId, setChosenSupplierId] = useState("TODOS")
  const [chosenOrderId, setChosenOrderId] = useState("TODOS")
  const navigate = useNavigate()

  const handleSearchButtonClick = (e) => {
    e.preventDefault()
    handleFilterChange({ chosenOrderId, chosenSupplierId, searchTerm })
  }

  const clearFilters = () => {
    setChosenSupplierId("TODOS")
    setChosenOrderId("TODOS")
  }

  const goToNfsePage = () => {
    navigate("/nfse")
  }

  return (
    <div className="mt-16 mb-8 flex flex-col w-full">
      <div className="flex flex-col sm:flex-row w-full">
        <form
          className="flex rounded-full shadow border-2 p-4 w-full"
          onSubmit={handleSearchButtonClick}
        >
          <input
            type="text"
            autoComplete="off"
            value={searchTerm}
            data-testid="search-input"
            onChange={(e) => setSearchTerm(e.target.value)}
            className="focus:outline-none w-full p-2 rounded-md rounded-br-none rounded-tr-none bg-blue-50 text-black"
            placeholder="Pesquisar NFSe"
            required
          />
          <button
            type="submit"
            onClick={handleSearchButtonClick}
            className="bg-primary p-2 text-white rounded-md rounded-tl-none rounded-bl-none font-medium hover:bg-blue-700 transition-all"
          >
            Pesquisar
          </button>
        </form>
        <div
          data-testid="new-nfse-button"
          onClick={goToNfsePage}
          className="flex items-center rounded-full shadow border-2 p-4 ml-0 mt-4 sm:ml-6 sm:mt-0 self-start sm:self-auto hover:scale-105 transition-all cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6 bg-primary rounded-full mr-2 text-white"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
          <p className="whitespace-nowrap text-lg text-black font-medium">
            Nova NFSe
          </p>
        </div>
      </div>
      <NfseSearchFilters
        clearFilters={clearFilters}
        chosenSupplierId={chosenSupplierId}
        setChosenSupplierId={setChosenSupplierId}
        setChosenOrderId={setChosenOrderId}
      />
    </div>
  )
}
