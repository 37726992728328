import SuppliersTableRow from "./SuppliersTableRow"

export default function SuppliersTable({ data: suppliers }) {
  return (
    <table data-testid="actual-table" className="w-full text-left">
      <thead className="border-b-2 border-black whitespace-nowrap">
        <tr>
          <th className="p-2 text-sm font-semibold tracking-wide">Nome</th>
          <th className="p-2 text-sm font-semibold tracking-wide">Documento</th>
          <th className="p-2 text-sm font-semibold tracking-wide">
            Município/UF
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-zinc-300 text-zinc-700 whitespace-nowrap">
        {suppliers?.map((supplier) => (
          <SuppliersTableRow key={supplier.id} supplier={supplier} />
        ))}
      </tbody>
    </table>
  )
}
