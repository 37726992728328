import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react"
import axios, { LOGIN_URL } from "../api/axios"
import { queryClient } from "../App"

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [loginInProgress, setLoginInProgress] = useState()
  const [loadingToken, setLoadingToken] = useState(true)
  const [isIntercepting, setIsIntercepting] = useState(false)
  const [token, setToken] = useState(null)
  const [error, setError] = useState(null)
  const [userId, setUserId] = useState(null)

  const logIn = async (body) => {
    try {
      setLoginInProgress(true)
      const response = await axios.post(LOGIN_URL, JSON.stringify(body))
      const token = response?.data?.token
      const expDate = response?.data?.exp
      const userId = response?.data?.user_id
      localStorage.setItem("user", userId)
      localStorage.setItem("token", token)
      localStorage.setItem("token_exp_date", expDate)
      setToken(token)
      setIsLoggedIn(true)
      setUserId(userId)
    } catch (error) {
      if (!error.response) setError("O servidor não está funcionando.")
      setError("Usuário ou senha inválidos.")
    }
    setLoginInProgress(false)
  }

  const logOut = useCallback(() => {
    localStorage.removeItem("token")
    localStorage.removeItem("token_exp_date")
    localStorage.removeItem("user")
    setToken(null)
    setError(null)
    setUserId(null)
    setIsLoggedIn(false)
    queryClient.clear()
  }, [])

  useEffect(() => {
    if (loadingToken) return
    const requestIntercept = axios.interceptors.request.use((config) => {
      if (!config.headers["Authorization"]) {
        config.headers["Authorization"] = token
      }
      return config
    })

    const responseIntercept = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const tokenHasExpired = error?.response?.status === 403
        // let previousRequest = error?.config
        // if (tokenHasExpired && !previousRequest.sent) {
        //     previousRequest.sent = true
        //     // get new access token with refresh token (http only)
        //     // previousRequest.headers["authorization"] = await getNewAccessToken()
        //     return axios(previousRequest)
        // } else if (tokenHasExpired) {
        //     logOut()
        //     return Promise.reject(error)
        // }
        if (tokenHasExpired) {
          logOut()
          return Promise.reject(error)
        }
        return Promise.reject(error)
      }
    )

    setIsIntercepting(true)

    return () => {
      axios.interceptors.request.eject(requestIntercept)
      axios.interceptors.response.eject(responseIntercept)
    }
  }, [logOut, token, loadingToken])

  useEffect(() => {
    const token = localStorage.getItem("token")
    const expDate = localStorage.getItem("token_exp_date")
    const userId = localStorage.getItem("user")
    const hasExpired = Date.now() >= new Date(expDate)
    setLoadingToken(false)
    if (!token || hasExpired) return logOut()
    setToken(token)
    setIsLoggedIn(true)
    setUserId(userId)
  }, [logOut])

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        logIn,
        logOut,
        loadingToken,
        error,
        token,
        loginInProgress,
        isIntercepting,
        userId,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export default function useAuth() {
  const values = useContext(AuthContext)

  return values
}
