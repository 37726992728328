/* eslint-disable */
import { useState } from "react"
import PropTypes from "prop-types"
import { getIn } from "formik"
import { useCallback, useEffect } from "react"

export default function TextFormField({
  field,
  form,
  mask,
  callback,
  inputClasses,
  containerClasses,
  labelClasses,
  errorClasses,
  ...props
}) {
  const handleChangeWithMask = useCallback(
    (e) => {
      const value = mask(e.target.value)
      form.setFieldValue(field.name, value)
    },
    [mask, field.name, form]
  )
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name)
  const currentValue = getIn(form.values, field.name)
  const [serverStatus, setServerStatus] = useState(null)

  useEffect(
    () => {
      let isCurrent = true
      const { value, error } = callback?.[0]
        ? callback[0]()
        : { value: null, error: null }
      if (isCurrent && (value || value === "")) {
        setServerStatus(value)
        form.setFieldValue(field.name, value)
      } else if (isCurrent && error) {
        setServerStatus(null)
        form.setFieldValue(field.name, "")
      }

      return () => {
        isCurrent = false
        form.setFieldValue(field.name, "")
      }
    },
    callback?.[1] ? [...callback[1], callback[0]] : []
  )

  return (
    <div
      className={`flex flex-col w-full text-black relative p-3 overflow-hidden ${
        containerClasses ? containerClasses : ""
      }`}
    >
      <input
        size={8}
        className={`peer rounded px-3 py-2 focus:outline-none border-[1px] font-medium focus:border-4 focus:px-2 border-primary border-opacity-40 transition-all ${
          errorText && !serverStatus
            ? "border-red-300 text-red-600"
            : "disabled:bg-zinc-200"
        } ${inputClasses ? inputClasses : ""}`}
        type="text"
        aria-label={field.name}
        {...field}
        {...props}
        onChange={mask ? handleChangeWithMask : field.onChange}
      />
      <label
        htmlFor={field.name}
        className={`absolute pointer-events-none mx-2 px-1 translate-y-[9px] peer-focus:text-sm max-w-[calc(100%-(2*20px))] ellipsis ${
          currentValue !== "" ? "-translate-y-[10px] bg-[#fff] text-sm" : ""
        } rounded transition-all peer-focus:-translate-y-[10px] peer-focus:bg-[#fff] ${
          errorText && !serverStatus
            ? "text-red-600"
            : "peer-focus:text-zinc-600"
        } ${labelClasses ? labelClasses : ""}`}
      >
        {props.label}
      </label>
      {errorText && !serverStatus && (
        <p
          role="alert"
          htmlFor={field.name}
          className={`px-1 text-xs text-red-600 mt-2 ${
            errorClasses ? errorClasses : ""
          }`}
        >
          {errorText}
        </p>
      )}
    </div>
  )
}

TextFormField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  mask: PropTypes.func,
  callback: PropTypes.array,
  label: PropTypes.string,
  maxLength: PropTypes.string,
  containerClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  errorClasses: PropTypes.string,
}
