import { Route, Routes, Navigate, useLocation } from "react-router-dom"
import useAuth from "./contexts/authContext"
import Nav from "./components/Nav"
import Login from "./pages/login/index"
import NoMatch from "./pages/404/index"
import Home from "./pages/home/index"
import Nfse from "./pages/new_nfse/index"
import Suppliers from "./pages/suppliers/index"
import NewSupplier from "./pages/new_supplier/index"
import Users from "./pages/users/index"
import NewUser from "./pages/new_user/index"

const ProtectedRoute = ({ isAllowed, redirectPath = "/login", children }) => {
  const location = useLocation()
  if (!isAllowed)
    return <Navigate to={redirectPath} state={{ from: location }} replace />
  return children
}

export default function _Routes() {
  const { isLoggedIn, loadingToken, isIntercepting } = useAuth()
  const location = useLocation()
  const fromPath = location.state?.from?.pathname

  if (loadingToken || !isIntercepting) return <></>

  return (
    <Routes>
      <Route
        path="/login"
        element={
          <ProtectedRoute
            redirectPath={fromPath || "/"}
            isAllowed={!isLoggedIn}
          >
            <Login />
          </ProtectedRoute>
        }
      />

      <Route
        path="/"
        element={
          <ProtectedRoute
            redirectPath={fromPath || "/login"}
            isAllowed={isLoggedIn}
          >
            <Nav />
            <Home />
          </ProtectedRoute>
        }
      />

      {["/nfse", "/nfse/:id"].map((path, index) => (
        <Route
          key={index}
          path={path}
          element={
            <ProtectedRoute
              redirectPath={fromPath || "/login"}
              isAllowed={isLoggedIn}
            >
              <Nav />
              <Nfse />
            </ProtectedRoute>
          }
        />
      ))}

      <Route
        path="/suppliers"
        element={
          <ProtectedRoute
            redirectPath={fromPath || "/login"}
            isAllowed={isLoggedIn}
          >
            <Nav />
            <Suppliers />
          </ProtectedRoute>
        }
      />

      {["/suppliers/new", "/suppliers/:id"].map((path, index) => (
        <Route
          key={index}
          path={path}
          element={
            <ProtectedRoute
              redirectPath={fromPath || "/login"}
              isAllowed={isLoggedIn}
            >
              <Nav />
              <NewSupplier />
            </ProtectedRoute>
          }
        />
      ))}

      <Route
        path="/users"
        element={
          <ProtectedRoute
            redirectPath={fromPath || "/login"}
            isAllowed={isLoggedIn}
          >
            <Nav />
            <Users />
          </ProtectedRoute>
        }
      />

      {["/users/new", "/users/:id"].map((path, index) => (
        <Route
          key={index}
          path={path}
          element={
            <ProtectedRoute
              redirectPath={fromPath || "/login"}
              isAllowed={isLoggedIn}
            >
              <Nav />
              <NewUser />
            </ProtectedRoute>
          }
        />
      ))}

      <Route
        path="*"
        element={
          <ProtectedRoute
            redirectPath={fromPath || "/login"}
            isAllowed={isLoggedIn}
          >
            <Nav />
            <NoMatch />
          </ProtectedRoute>
        }
      />
    </Routes>
  )
}
