export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1))
    let temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
  return array
}

export const getAllChars = () => {
  let allChars = ""
  for (let i = 48; i < 123; i++) {
    if (i >= 58 && i <= 64) continue
    if (i >= 91 && i <= 96) continue
    allChars += String.fromCharCode(i)
  }
  return allChars
}

export const selectReduceData = ({ data, attributeName }) =>
  data.reduce((total, current) => {
    const value = current.id
    const label = current.attributes[attributeName]
    return [...total, { value, label }]
  }, [])
