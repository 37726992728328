/* eslint-disable */
import { useEffect } from "react"
import PropTypes from "prop-types"
import { getIn } from "formik"

export default function CheckboxFormField({
  field,
  form,
  label,
  inputClasses,
  labelClasses,
  callback,
  ...props
}) {
  const errorText =
    getIn(form.touched, field.name) && getIn(form.errors, field.name)

  useEffect(
    () => {
      let isCurrent = true
      const { value, error } = callback?.[0]
        ? callback[0]()
        : { value: null, error: null }
      if (isCurrent && (value || value === "")) {
        form.setFieldValue(field.name, value)
      } else if (isCurrent && error) {
        form.setFieldValue(field.name, "")
      }

      return () => {
        isCurrent = false
        form.setFieldValue(field.name, "")
      }
    },
    callback?.[1] ? [...callback[1], callback[0]] : []
  )

  return (
    <>
      <input
        className={`opacity-0 h-0 w-0 cursor-pointer absolute peer ${
          errorText ? "" : ""
        } ${inputClasses ? inputClasses : ""}`}
        type="checkbox"
        checked={field.value}
        id={field.name}
        aria-label={field.name}
        {...field}
        {...props}
      />
      <label
        htmlFor={field.name}
        className={`relative cursor-pointer inline-block text-zinc-600 text-xs sm:text-sm pl-[22px] sm:leading-4 transition-all before:content-[""] before:absolute before:w-4 before:h-4 before:border-[1px] before:border-zinc-400 before:rounded-sm before:bg-white before:left-0 before:top-0 peer-checked:before:border-primary after:transition-all before:transition-all after:scale-0 peer-checked:after:scale-100 peer-checked:after:top-0 peer-checked:after:content-[""] peer-checked:after:left-0 peer-checked:after:absolute peer-checked:after:w-4 peer-checked:after:h-4 peer-checked:after:bg-contain peer-checked:after:bg-[url("./assets/check.svg")] ${
          errorText ? "before:border-red-300 before:bg-red-50 text-red-400" : ""
        } ${labelClasses ? labelClasses : ""}`}
      >
        {label}
      </label>
    </>
  )
}

CheckboxFormField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  label: PropTypes.string,
  callback: PropTypes.array,
}
