import { useState, useMemo, useCallback, useEffect } from "react"
import { useFormikContext, Form, Field } from "formik"
import TextFormField from "../../components/forms/TextFormField"
import SelectFormField from "../../components/forms/SelectFormField"
import TextAreaFormField from "../../components/forms/TextAreaFormField"
import AutoCompleteFormField from "../../components/forms/AutoCompleteFormField"
import CheckboxFormField from "../../components/forms/CheckboxFormField"
import ToogleFormField from "../../components/forms/ToogleFormField"
import { useQuery } from "react-query"
import {
  getOrders,
  getCNAECodes,
  getCountyRules,
  getTaxCodes,
  getServiceItems,
} from "../../api/NFSeServices"
import { OPERATION_NATURE_VALUES } from "./NFSeSchema"
import { selectReduceData } from "../../lib/utils"
import useTimeout from "../../lib/useTimeout"
import NFSeFormAdditionalInfo from "./NFSeFormAdditionalInfo"
import { useNavigate } from "react-router-dom"

export default function NFSeForm({
  nfse,
  suppliers,
  companiesData,
  isSubmitting,
  ...props
}) {
  const isDisabled = !!nfse
  const navigate = useNavigate()
  const { values, setFieldValue } = useFormikContext()
  const [copiedToClipboard, setCopiedToClipboard] = useState(false)
  const { reset } = useTimeout(() => setCopiedToClipboard(false), 1500)

  const copyToClipboard = async (text) => {
    await navigator.clipboard.writeText(text)
    setCopiedToClipboard(true)
    reset()
  }

  const getCountyCodeSupplierId = (id) => {
    const suppliersData = suppliers?.data?.data
    const supplier = suppliersData?.find(
      (supplier) => Number(supplier.id) === Number(id)
    )
    const countyCode = supplier?.attributes["codigo-municipio"]
    return countyCode
  }

  const suppliersData = useMemo(() => {
    const suppliersData = suppliers?.data?.data
    if (!suppliersData || suppliersData.lenght === 0) return []
    return selectReduceData({
      data: suppliersData,
      attributeName: "razao-social",
    })
  }, [suppliers])

  const { data: countyRules } = useQuery(
    ["county", getCountyCodeSupplierId(values.supplier_id)],
    getCountyRules
  )

  const setRequired = useCallback(
    (countyRules) => {
      const cnaeRequired = countyRules?.data?.codigo_cnae_obrigatorio_nfse
      const countyCodeRequired =
        countyRules?.data?.codigo_tributario_municipio_obrigatorio_nfse
      const serviceListItemRequired =
        countyRules?.data?.item_lista_servico_obrigatorio_nfse
      setFieldValue(
        "codigo_item_lista_servico_obrigatorio",
        !!serviceListItemRequired
      )
      setFieldValue(
        "codigo_tributario_municipio_obrigatorio",
        !!countyCodeRequired
      )
      setFieldValue("codigo_cnae_obrigatorio", !!cnaeRequired)
    },
    [setFieldValue]
  )

  useEffect(() => {
    setRequired(countyRules)
  }, [setRequired, countyRules])

  const { data: orders } = useQuery(["orders", values.supplier_id], getOrders)
  const ordersData = useMemo(() => {
    const ordersData = orders?.data?.data
    if (!ordersData || ordersData.lenght === 0) return []
    return selectReduceData({ data: ordersData, attributeName: "description" })
  }, [orders])

  const normalizeRequiredField = (data) => {
    if (!data || data?.lenght === 0) return []
    return data.reduce((total, current) => {
      const label = `${current.codigo} - ${current.descricao}`
      return [...total, { label }]
    }, [])
  }

  const { data: taxCodes } = useQuery(
    ["taxCodes", getCountyCodeSupplierId(values.supplier_id)],
    isDisabled ? () => {} : getTaxCodes
  )
  const taxCodesData = useMemo(() => {
    const taxCodesData = taxCodes?.data
    return normalizeRequiredField(taxCodesData)
  }, [taxCodes])

  const { data: serviceItems } = useQuery(
    ["serviceItems", getCountyCodeSupplierId(values.supplier_id)],
    isDisabled ? () => {} : getServiceItems
  )
  const serviceItemsData = useMemo(() => {
    const serviceItemsData = serviceItems?.data
    return normalizeRequiredField(serviceItemsData)
  }, [serviceItems])

  const { data: CNAECodes } = useQuery(
    ["CNAECodes"],
    isDisabled ? () => {} : getCNAECodes
  )
  const CNAECodesData = useMemo(() => {
    const CNAECodesData = CNAECodes?.data
    return normalizeRequiredField(CNAECodesData)
  }, [CNAECodes])

  const goToNFSePage = () => {
    navigate("/")
  }

  return (
    <Form className="w-full mb-80">
      <p
        onClick={goToNFSePage}
        className="underline w-fit mb-2 mt-12 text-xs text-primary hover:text-blue-400 cursor-pointer"
      >
        Voltar para a página de NFSe
      </p>
      <div className="w-full rounded-md md:p-4 border-2">
        <div className="grid grid-cols-1">
          <Field
            disabled={isDisabled}
            options={suppliersData ? suppliersData : []}
            name="supplier_id"
            label="Fornecedor *"
            component={SelectFormField}
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2">
          <Field
            disabled={isDisabled}
            options={companiesData ? companiesData : []}
            name="company_id"
            label="Empresa *"
            component={SelectFormField}
          />
          <Field
            disabled={isDisabled}
            options={ordersData ? ordersData : []}
            name="order_id"
            label="N° do pedido"
            component={SelectFormField}
          />
        </div>
      </div>
      <div className="w-full rounded-md md:p-4 mt-12 border-2">
        <div className="grid grid-cols-1 md:grid-cols-3">
          <Field
            disabled={isDisabled}
            labelClasses={"-translate-y-[10px] bg-[#fff] text-sm"}
            name="data_emissao"
            label="Data da emissão *"
            component={TextFormField}
            type="date"
          />
          <Field
            disabled={isDisabled}
            name="numero"
            label="Número *"
            component={TextFormField}
            type="number"
          />
          <Field
            disabled={isDisabled}
            name="codigo_verificacao"
            label="Código de verificação *"
            component={TextFormField}
          />
          <Field
            disabled={isDisabled}
            options={OPERATION_NATURE_VALUES}
            name="natureza_operacao"
            label="Natureza da Operação"
            component={SelectFormField}
          />
          <Field
            disabled={isDisabled}
            name="numero_rps"
            label="Número RPS"
            component={TextFormField}
            type="number"
          />
          <Field
            disabled={isDisabled}
            name="serie_rps"
            label="Série RPS"
            component={TextFormField}
          />
        </div>
        <div className="grid grid-cols-1">
          <div className="flex">
            <Field
              disabled={isDisabled}
              name="url"
              label="URL da nota *"
              component={TextFormField}
            />
            {copiedToClipboard ? (
              <svg
                onClick={() => copyToClipboard(values.url)}
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mt-5 mr-4 cursor-pointer hover:scale-125 text-green-600 transition-all"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M9 2a1 1 0 000 2h2a1 1 0 100-2H9z" />
                <path
                  fillRule="evenodd"
                  d="M4 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v11a2 2 0 01-2 2H6a2 2 0 01-2-2V5zm9.707 5.707a1 1 0 00-1.414-1.414L9 12.586l-1.293-1.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                onClick={() => copyToClipboard(values.url)}
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 mt-5 mr-4 cursor-pointer hover:scale-125 text-black transition-all"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2"
                />
              </svg>
            )}
          </div>
          <div className="grid grid-cols-1">
            <Field
              disabled={isDisabled}
              name="discriminacao"
              label="Discriminação *"
              component={TextAreaFormField}
            />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3">
            {values.codigo_cnae_obrigatorio && (
              <>
                <Field
                  disabled={isDisabled}
                  options={CNAECodesData}
                  name="codigo_cnae"
                  label="Código CNAE *"
                  component={AutoCompleteFormField}
                />
              </>
            )}
            {values.codigo_tributario_municipio_obrigatorio && (
              <>
                <Field
                  disabled={isDisabled}
                  options={taxCodesData}
                  name="codigo_tributario_municipio"
                  label="Código tributário município *"
                  component={AutoCompleteFormField}
                />
              </>
            )}
            {values.codigo_item_lista_servico_obrigatorio && (
              <>
                <Field
                  disabled={isDisabled}
                  options={serviceItemsData}
                  name="codigo_item_lista_servico"
                  label="Item lista serviço *"
                  component={AutoCompleteFormField}
                />
              </>
            )}
            <Field
              disabled={isDisabled}
              name="aliquota"
              label="Alíquota ISS"
              component={TextFormField}
              type="number"
            />
            <Field
              disabled={isDisabled}
              name="valor_iss"
              label="Valor ISS"
              component={TextFormField}
              type="number"
            />
            <div className="flex w-full">
              <div className="my-6 pl-3">
                <Field
                  disabled={isDisabled}
                  name="iss_retido"
                  label="ISS Retido"
                  component={ToogleFormField}
                />
                <p></p>
              </div>
              {values.iss_retido && (
                <>
                  <Field
                    disabled={isDisabled}
                    name="valor_iss_retido"
                    label="Valor ISS Retido *"
                    component={TextFormField}
                    type="number"
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <NFSeFormAdditionalInfo isDisabled={isDisabled} />
      <div className="w-full rounded-md md:p-4 border-2">
        <div className="grid grid-cols-1 sm:grid-cols-3">
          <Field
            disabled={isDisabled}
            name="valor_servicos"
            label="Valor total da nota *"
            component={TextFormField}
            type="number"
          />
        </div>
        {!isDisabled && (
          <div className="flex mx-3 my-2">
            <Field
              label="Confirmo a autenticidade das informações e dou garantia de que a nota acima mencionada foi de fato emitida."
              name="dataAuthenticity"
              component={CheckboxFormField}
            />
          </div>
        )}
        {!isDisabled && (
          <button disabled={isSubmitting} className="button m-2" type="submit">
            Salvar
          </button>
        )}
      </div>
    </Form>
  )
}
