import { useState, useRef, useEffect, useCallback } from "react"
import { useQuery } from "react-query"
import { Link, useLocation } from "react-router-dom"
import { getUser } from "../api/usersServices"
import useAuth from "../contexts/authContext"

export default function Nav() {
  const navBarRef = useRef()
  const menuRef = useRef()
  const { pathname } = useLocation()
  const { logOut, userId } = useAuth()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [prevScroll, setPrevScroll] = useState(0)
  const { data: user } = useQuery(["user", userId], getUser)
  const isAdmin =
    user?.data?.data?.attributes?.admin !== false &&
    user?.data?.data?.attributes?.admin !== "undefined"

  const handleToggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState)
  }

  const closeMenu = () => {
    setIsMenuOpen(false)
  }

  const goToTop = () => {
    window.scrollTo({ top: 0 })
  }

  const handleLogout = () => {
    logOut()
  }

  const handleScroll = useCallback(() => {
    if (navBarRef) {
      const currentScroll = window.scrollY
      if (prevScroll >= currentScroll)
        navBarRef.current.style.transform = "translateY(0px)"
      else navBarRef.current.style.transform = "translateY(-80px)"
      setPrevScroll(currentScroll)
    }
  }, [prevScroll])

  const handleClickOutside = useCallback(
    (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu()
      }
    },
    [menuRef]
  )

  useEffect(() => {
    document.addEventListener("scroll", handleScroll, false)
    return () => {
      document.removeEventListener("scroll", handleScroll, false)
    }
  }, [handleScroll])

  useEffect(() => {
    closeMenu()
  }, [pathname])

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [handleClickOutside])

  return (
    <>
      <nav
        ref={navBarRef}
        className="sticky top-0 transition-all duration-300 left-0 z-10 flex justify-center items-center p-3 w-full border-b-4 bg-[#fff] border-primary shadow-xl"
      >
        <div className="flex w-full max-w-7xl justify-center items-center">
          <div onClick={handleToggleMenu}>
            <svg
              role="menubar"
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 text-right text-primary hover:text-blue-700 transition-all cursor-pointer"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </div>
          <div className="flex w-full justify-center pointer-events-none md:-ml-8">
            <Link onClick={goToTop} to="/" className="flex pointer-events-auto">
              <img alt="logo" width={132} src="/logo_focus_nfe.png" />
              <div className="pl-4 flex-col justify-center items-center md:flex hidden">
                <h1 className="text-primary font-extrabold text-xl self-end translate-y-[6px]">
                  Portal de entrada de NFSe
                </h1>
              </div>
            </Link>
          </div>
        </div>
      </nav>
      <menu
        ref={menuRef}
        role="menu"
        className={`fixed top-0 left-0 h-screen max-w-sm w-full bg-primary z-30 flex flex-col justify-between shadow-lg transition-all ${
          isMenuOpen ? "" : "-translate-x-96"
        }`}
      >
        <div className="flex flex-col h-full w-full overflow-auto py-2 scrollbar">
          <svg
            role="switch"
            onClick={closeMenu}
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-8 bg-primary rounded-full absolute right-3 top-3 fill-red-500 hover:fill-red-600 cursor-pointer"
            viewBox="0 0 20 20"
            fill=""
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
          <div className="flex flex-col w-full justify-center items-center text-center pb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-20 w-20 mt-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="white"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <hr />
          <ul className="text-center text-white">
            <Link to="/">
              <li className="py-2 transition-all hover:bg-blue-600 text-lg font-medium">
                NFSe
              </li>
            </Link>
            {isAdmin && (
              <>
                <Link to="/suppliers">
                  <li className="py-2 transition-all hover:bg-blue-600 text-lg font-medium">
                    Fornecedores
                  </li>
                </Link>
                <Link to="/users">
                  <li className="py-2 transition-all hover:bg-blue-600 text-lg font-medium">
                    Usuários
                  </li>
                </Link>
              </>
            )}
          </ul>
        </div>
        <div className="flex w-full flex-col justify-center items-center py-2">
          <p
            onClick={handleLogout}
            className="py-2 text-red-500 hover:text-red-600 cursor-pointer my-2 w-full text-center transition-all hover:bg-blue-600 text-lg font-medium"
          >
            Sair da conta
          </p>
          <p className="text-xs text-zinc-200">Copyright © | Focus NFe</p>
        </div>
      </menu>
      <div
        className={`fixed top-0 left-0 z-20 overflow-hidden w-full h-screen transition-all ${
          isMenuOpen
            ? "bg-[#000] bg-opacity-70 pointer-events-auto"
            : "pointer-events-none bg-transparent"
        }`}
      />
    </>
  )
}
